.section-confirmation {
    margin-bottom: px-to-rem(67);

    .section__title {
        @include media-breakpoint-up(lg) {
            margin-bottom: px-to-rem(40);
        }

        span {
            color: $clr-orange;
            font-weight: $font-weight-bold;
        }
    }

    .section__sidebar {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .section__sidebar-inner {
        padding-top: px-to-rem(32);

        @include media-breakpoint-up(lg) {
            padding-top: px-to-rem(265);
        }
    }

    .confirmation-banner {
        border-radius: px-to-rem(19);
        max-width: 100%;
        height: auto;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    .order-thank-you-email-msg {
        font-size: font-size('body-lg', 'desktop');
        font-weight: $font-weight-normal;
        line-height: px-to-rem(26);
        color: $clr-darkergrey;
        margin-bottom: sp(5);

        @include media-breakpoint-up(lg) {
            font-size: font-size('body-xl', 'desktop');
            margin-bottom: sp(6);
        }

        span {
            font-weight: $font-weight-bold;
            display: block;
        }
    }

    .order-validation-info {
        font-size: font-size('body-lg', 'desktop');
        font-weight: $font-weight-normal;
        line-height: px-to-rem(26);
        color: $clr-darkergrey;
        margin-bottom: px-to-rem(20);

        @include media-breakpoint-up(lg) {
            font-size: font-size('body-xl', 'desktop');
            margin-bottom: px-to-rem(40);
        }
    }

    &__link-order-details {
        text-decoration: none;
        font-weight: $font-weight-semibold;

        .icon {
            display: inline-flex;
            width: px-to-rem(21);
            justify-content: center;
        }
    }

    .card.checkout-order-total-summary {
        margin-bottom: px-to-rem(40);
    }
}