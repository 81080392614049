$icomoon-font-family: "hf-icons" !default;
$icomoon-font-path: "../../fonts" !default;
$icons: (
    "eye": "\e912",
    "double-checkmark": "\e913",
    "arrow-left": "\e907",
    "arrow-right": "\e908",
    "bin": "\e909",
    "chevron-down": "\e90a",
    "chevron-up": "\e90b",
    "chronometer": "\e90c",
    "circle-info": "\e90d",
    "circle-minus": "\e90e",
    "circle-plus": "\e90f",
    "edit": "\e910",
    "plus": "\e911",
    "circle-question": "\e901",
    "circle-user": "\e902",
    "lock": "\e903",
    "quotes": "\e900",
    "search": "\e904",
    "close": "\e905",
    "menu": "\e906",
);

@each $name, $glyph in $icons {
    .icon-#{$name} {
        &::before {
            content: $glyph;
        }
    }
}

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9py7d2') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9py7d2') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9py7d2##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}