@mixin text-line-clamp($line) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @supports (-webkit-line-clamp: $line) {
        white-space: initial;
        display: -webkit-inline-box;
        max-height: none;

        /* autoprefixer: off */
        -webkit-box-orient: vertical;

        /* autoprefixer: on */
        -webkit-line-clamp: $line;
        overflow: hidden;
    }
}

@mixin user-select-none {
    user-select: none;
}

@mixin text-size($font-size: null, $line-height: null, $font-weight: normal) {
  font-size: px-to-rem($font-size);
  line-height: px-to-rem($line-height);

  @if $font-weight {
    font-weight: $font-weight;
  }
}

@mixin make-flex($flex-direction, $flex-align: null, $flex-justify: null) {
    @if $flex-direction != row and $flex-direction != row-reverse and $flex-direction != column and $flex-direction != column-reverse {
      @error "Property #{$flex-direction} must be either row or column.";
    }

    display: flex;
    flex-direction: $flex-direction;
    align-items: $flex-align;
    justify-content: $flex-justify;
}

@mixin object-fit($fit: cover, $pos: null) {
    object-fit: $fit;

    @if $pos {
        object-position: $pos;
    }
}

@mixin font-weight($font-weight) {
  font-weight: map.get($font-weights, $font-weight);
}

@mixin custom-scrollbars($size: px-to-rem(4), $radius: px-to-rem(12), $foreground-color: $clr-scrollbardark, $background-color: $clr-white) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $radius;
  }
}