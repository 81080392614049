body {
    line-height: $line-height-light;
    color: var(--bs-body-color);
    font-family: font-family(primary);
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* Accessibility Skip To Content Anchors */
.skip {
    position: absolute;
    left: 0;
    top: px-to-rem(-60);
    overflow: hidden;
    padding: px-to-rem(10);
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.disabled {
    pointer-events: none;
}

.divider {
    height: px-to-rem(1);
    line-height: px-to-rem(1);
    border-top: solid px-to-rem(1) rgba($clr-black, 0.1);
}

.mobile-only {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.desktop-only {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
