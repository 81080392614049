.section {
    padding: sp(5) 0;

    @include media-breakpoint-up(lg) {
        padding: sp(6) 0;
    }

    &__sidebar-inner {
        @include media-breakpoint-up(lg) {
            padding: 0 px-to-rem(20);
        }
    }

    &__title {
        @include text-size(36, 40, 600);

        margin-bottom: sp(5);
        color: $clr-orange;
    }
}

.prompt {
    display: none;
}

.icon {
    font-size: px-to-rem(14);

    &:hover {
        cursor: pointer;

        ~ .prompt {
            position: absolute;
            display: block;
            width: 100%;
            bottom: px-to-rem(40);
            left: 0;
            padding: px-to-rem(10);
            border-radius: px-to-rem(10);
            color: $clr-darkgrey;
            font-size: px-to-rem(10);
            font-weight: $font-weight-normal;
            box-shadow: $box-shadow;
            background-color: $white;
        }
    }
}

.grecaptcha-badge {
    z-index: $z-index-popover - 1;
}