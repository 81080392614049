.checkout-header {
    $parent: &;

    padding-top: px-to-rem(9);
    color: $clr-dark;
    min-height: px-to-rem(48);

    @include media-breakpoint-up(lg) {
        padding-top: px-to-rem(46);
        min-height: px-to-rem(92);
    }

    a {
        text-decoration: none;
    }

    &__nav-inner {
        @include make-flex(row, flex-end, space-between);

        position: relative;

        @include media-breakpoint-up(lg) {
            align-items: center;
        }
    }

    &__logo {
        position: absolute;
        display: flex;
        width: px-to-rem(142);
        height: px-to-rem(36);
        top: px-to-rem(3);
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
            top: px-to-rem(-14);
            width: px-to-rem(237);
            height: px-to-rem(60);
        }

        img {
            width: 100%;
        }
    }

    .return {
        @include make-flex(row, baseline, null);

        gap: px-to-rem(5);
        font-size: font-size('body-xs','mobile');
        font-weight: $font-weight-semibold;
        color: $clr-dark;
        background: none;
        border: none;
        padding: 0;

        @include media-breakpoint-up(lg) {
            font-size: font-size('body-sm','desktop');
        }

        &-text {
            line-height: px-to-rem(15);

            @include media-breakpoint-up(lg) {
                line-height: px-to-rem(20);
            }
        }

        .icon {
            @include make-flex(row, center, center);

            width: px-to-rem(16);
            height: px-to-rem(16);

            @include media-breakpoint-up(lg) {
                width: px-to-rem(21);
                height: px-to-rem(21);
            }
        }
    }

    .header__user-dropdown {
        .user-name {
            display: none;
        }


        @include media-breakpoint-up(lg) {
            .user-name {
                display: inline;
                margin-left: 0;
                margin-right: px-to-rem(10);
            }
        }

        @include media-breakpoint-down(lg) {
            .dropdown-item {
                padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
            }

            .user-avatar {
                margin-bottom: px-to-rem(3);
            }
        }
    }

    .header__user-toggle {
        flex-direction: row-reverse;
    }
}