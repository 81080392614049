
$font-sizes-map: (
    h1         : (desktop: 66, mobile: 36), /* Disclaimer: Use without unit. */
    h2         : (desktop: 46, mobile: 30),
    h3         : (desktop: 26, mobile: 22),
    h4         : (desktop: 20, mobile: 20),
    h5         : (desktop: 18, mobile: 16), 
    h6         : (desktop: 16, mobile: 14),
    h7         : (desktop: 14, mobile: 12),
    body-xl    : (desktop: 22, mobile: 22),
    body-lg    : (desktop: 18, mobile: 16),
    body-sm    : (desktop: 16, mobile: 14),
    body-xs    : (desktop: 14, mobile: 12),
    cta        : (desktop: 16, mobile: 14),
);
$font-weights: (
    light      : 300,
    regular    : 400,
    medium     : 500,
    semibold   : 600,
    bold       : 700,
);
$font-weight-of-size-types: (
    h1: semibold,
    h2: semibold,
    h3: semibold,
    h4: semibold,
    h5: semibold,
    h6: semibold,
    h7: semibold,
    cta: semibold,
    body-lg: regular,
    body-sm: regular,
    body-xs: regular,
);

// Bootstrap gives all font weights $font-weight-{...} except for medium, so it must be added

$font-weight-medium: 500;

@each $font-size, $font-size-value in $font-sizes-map {
    @each $device, $value in $font-size-value {
        @if $device == 'desktop' {
            $device: 'd';
        } @else if $device == 'mobile' {
            $device: 'm';
        }

       .fs-#{$font-size}__#{$device} {
        font-size: px-to-rem($value);
       }
    }
}

@function font-size($font-size, $device: desktop) {
    $size: map.get($font-sizes-map, $font-size);
    $font-size-value: px-to-rem(map.get($size, $device));
    @return $font-size-value;
}

@function font-weight($font-size) {
    $font-weight: map.get($font-weight-of-size-types, $font-size);
    $weight-number: map.get($font-weights, $font-weight);

    @return $weight-number;
}