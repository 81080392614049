.checkout {
    .summary-floa {
        position: sticky;
        top: px-to-rem(32);
        margin-top: px-to-rem(128);
    }

    &[data-checkout-stage="shipping"],
    &[data-checkout-stage="submitted"],
    &[data-checkout-stage="placeOrder"] {
        .floa-widget-container {
            display: none;
        }
    }

    &[data-checkout-stage="payment"] {
        .floa-widget-container {
            border: 0.125rem solid #009fff;
            background-color: #fff;
            border-radius: 1.25rem;
            margin-top: 20px;
            padding: 2rem;

            &__name {
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 2rem;
                color: #009fff;
                padding-bottom: 2.125rem;
                margin-bottom: 2.125rem;
                border-bottom: 0.0625rem solid #009fff;
            }

            &__information {
                color: var(--fl-gray);
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .summary {
        padding: px-to-rem(32);
        color: $white;
        background-color: $clr-darkergrey;
        border-radius: px-to-rem(20);
        line-height: px-to-rem(26);
        font-weight: $font-weight-normal;

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(32);
            padding: px-to-rem(20);
        }

        &-container {
            width: px-to-rem(479);

            @include media-breakpoint-down(lg) {
                width: 100%;
                margin-bottom: px-to-rem(65);
            }
        }

        &-product {
            &-price {
                padding: px-to-rem(16) 0;
                border-bottom: px-to-rem(1) solid $clr-orange;
            }

            &__name {
                font-size: px-to-rem(26);
                font-weight: $font-weight-semibold;
                line-height: px-to-rem(32);
                color: $clr-borderorange;

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(20);
                    line-height: px-to-rem(24);
                }
            }
        }

        &-delivery {
            padding: px-to-rem(16) 0;
            border-bottom: px-to-rem(1) solid $clr-orange;
        }

        &-total {
            &__price {
                padding: px-to-rem(16) 0;
                font-size: px-to-rem(24);
                font-weight: $font-weight-bold;
                line-height: px-to-rem(30);
            }
        }

        &-modify {
            &__link {
                color: $white;
                font-size: px-to-rem(14);
                font-weight: $font-weight-normal;
                line-height: px-to-rem(17);
            }
        }
    }

    &-stages {
        @include make-flex(row, center, center);

        margin: px-to-rem(64) 0 0;
        font-size: px-to-rem(18);
        font-weight: $font-weight-bold;
        line-height: px-to-rem(22);

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            margin: px-to-rem(64) px-to-rem(16) 0;
            font-size: px-to-rem(16);
            line-height: px-to-rem(20);
            word-break: break-word;
        }

        .stage {
            &-block {
                width: px-to-rem(313);
                padding-bottom: px-to-rem(18);
                text-align: center;
                border-bottom: px-to-rem(1) solid $clr-darkergrey;
                color: $clr-darkergrey;
                text-decoration: none;
                pointer-events: none;

                @include media-breakpoint-down(lg) {
                    @include make-flex(row, flex-start, center);

                    width: 100%;
                    padding: px-to-rem(18) 0;

                    &:first-child {
                        padding-top: 0;
                    }
                }

                &-text {
                    @include media-breakpoint-down(lg) {
                        text-align: left;
                        width: 100%;
                    }
                }
            }
        }
    }

    &[data-checkout-stage="shipping"] {
        .stage-block {
            &.stage-contact {
                color: $clr-orange;
                border-color: $clr-orange;
            }
        }

        .address-form {
            .saved-addresses-wrapper {
                display: block;

                &.submitted {
                    display: none;
                }
            }

            .shipping-form,
            .billing-form {
                &[data-form-collapsed="false"] {
                    display: none;
                }
            }

            .checkout-title {
                .checkout-title__shipping {
                    display: inline;
                }

                .checkout-title__payment {
                    display: none;
                }
            }
        }
    }

    &[data-checkout-stage="submitted"],
    &[data-checkout-stage="payment"],
    &[data-checkout-stage="placeOrder"] {
        .stage-block {
            &.stage-contact {
                pointer-events: auto;
                cursor: pointer;
            }

            &.stage-summary {
                color: $clr-orange;
                border-color: $clr-orange;
            }
        }

        .address-form {
            .form-group-submit {
                display: none;
            }

            .saved-addresses-wrapper {
                display: none;

                &.submitted {
                    display: block;
                }
            }

            .shipping-form,
            .billing-form {
                display: none;
            }

            .shipping-block,
            .billing-block {
                .cancel-address-adding {
                    display: none;
                }
            }

            .billing-address-submit {
                display: block;
            }

            .same-billing-address.form-group {
                display: none;

                &.checked {
                    + .billing-block {
                        display: block;
                    }
                }
            }

            .checkout-title {
                .checkout-title__shipping {
                    display: none;
                }

                .checkout-title__payment {
                    display: inline;
                }
            }
        }
    }

    &[data-checkout-stage="submitted"],
    &[data-checkout-stage="placeOrder"] {
        .address-form {
            display: none;
        }

        .payment-container {
            margin-top: px-to-rem(128);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(64);
            }
        }

        .place-order {
            margin-bottom: px-to-rem(64);
        }

        .summary {
            margin-bottom: px-to-rem(64);
            @include media-breakpoint-down(lg) {
                margin: 0;
            }
        }

        .stage-block {
            &.stage-contact {
                pointer-events: auto;
                cursor: pointer;
            }

            &.stage-summary {
                pointer-events: auto;
                cursor: pointer;
                color: $clr-darkergrey;
                border-color: $clr-darkergrey;
            }

            &.stage-payment {
                color: $clr-orange;
                border-color: $clr-orange;
            }
        }
    }

    &[data-checkout-stage="submitted"] {
        .btn.place-order {
            display: block;
        }
    }

    .address-form {
        .form {
            &-group {
                max-width: px-to-rem(624);

                &-row {
                    @include make-flex(row, flex-start, center);

                    gap: px-to-rem(21);
                }

                &__civility,
                &__customertype {
                    .form-label {
                        margin-bottom: px-to-rem(10);
                    }

                    .civility-label,
                    .customertype-label {
                        margin-right: px-to-rem(16);
                        padding: px-to-rem(9) px-to-rem(14);
                        border: px-to-rem(1) solid $clr-bordergrey;
                        border-radius: px-to-rem(5);
                        line-height: px-to-rem(17);
                        background-color: $white;
                    }

                    .civility-radio,
                    .customertype-radio {
                        width: 0;
                        position: absolute;
                        z-index: -1;

                        &:checked {
                            + .civility-label,
                            + .customertype-label {
                                border: px-to-rem(2) solid $clr-darkergrey;
                            }
                        }
                    }
                }

                &__postalcode {
                    position: relative;
                    min-width: 33%;
                    margin: 0;
                }

                &__city {
                    position: relative;
                    width: 100%;
                    margin: 0;
                }

                &.same-billing-address {
                    @include make-flex(row, center, flex-start);

                    gap: px-to-rem(10);
                    margin: px-to-rem(64) 0;

                    .form-check-input {
                        height: px-to-rem(26);
                        width: px-to-rem(26);
                        margin: 0;
                        border-radius: 0;
                        border-color: $clr-orange;
                    }

                    .form-label {
                        margin: 0;
                        font-weight: $font-weight-semibold;
                        line-height: px-to-rem(22);
                        color: $clr-orange;
                    }

                    &.checked {
                        + .billing-block {
                            display: none;
                        }
                    }

                    &.d-none {
                        + .form-container {
                            + .form-group-submit {
                                display: none;
                            }
                        }
                    }
                }
            }

            &-label {
                margin-bottom: px-to-rem(10);
                color: $clr-darkergrey;
            }

            &-container {
                &.d-none {
                    + .form-group {
                        display: none;
                    }
                }
            }
        }

        .address-block {
            &[data-customer-type="individual"] {
                .label-address1 {
                    &-individual {
                        display: inline;
                    }

                    &-company {
                        display: none;
                    }
                }
            }

            &[data-customer-type="company"] {
                .label-address1 {
                    &-individual {
                        display: none;
                    }

                    &-company {
                        display: inline;
                    }
                }
            }
        }

        .billing-block {
            .same-billing-address {
                margin: 0 0 px-to-rem(64);

                &.checked {
                    + .checkout-title {
                        display: none;

                        + .saved-addresses-wrapper {
                            display: none;
                        }
                    }
                }
            }
        }

        .shipping-block {
            .submitted {
                .comment {
                    display: block;
                }
            }
        }

        &-phone {
            &__description {
                padding-top: px-to-rem(8);
                font-size: px-to-rem(14);
                line-height: px-to-rem(18);
                color: $clr-bordergrey;
                font-weight: $font-weight-normal;
            }
        }

        &-container {
            width: px-to-rem(626);

            .next-step-button {
                padding: 0;
                background: none;
                box-shadow: none;

                @include media-breakpoint-down(lg) {
                    position: static;
                }

                .submit-shipping {
                    margin: px-to-rem(64) 0;
                }
            }
        }

        .comment {
            display: none;
            margin-top: px-to-rem(16);
            padding-top: px-to-rem(32);
            border-top: px-to-rem(1) solid $clr-grey;

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(20);
            }

            .checkboxes {
                @include make-flex(column, flex-start, flex-start);

                gap: px-to-rem(16);

                .checkbox-container {
                    @include make-flex(row, center, flex-start);

                    gap: px-to-rem(10);
                    font-size: px-to-rem(18);
                    font-weight: $font-weight-normal;
                    line-height: px-to-rem(22);

                    .form-check-input {
                        height: px-to-rem(26);
                        width: px-to-rem(26);
                        margin: 0;
                        border-radius: px-to-rem(5);
                        border: px-to-rem(1) solid $clr-bordergrey;
                        background-color: $white;

                        &:checked {
                            border: px-to-rem(1) solid $clr-orange;
                            background-color: $clr-orange;
                        }
                    }

                    .form-label {
                        margin: 0;
                    }
                }
            }

            &-textarea {
                padding: px-to-rem(16);
                height: px-to-rem(195);
                width: 100%;
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);
                font-weight: $font-weight-normal;
                background-color: $clr-textareagrey;
                color: $clr-darkergrey;
            }
        }

        .form-group-cancel {
            margin: 0;

            .cancel-address-adding {
                @include make-flex(row, center, flex-start);

                gap: px-to-rem(7);
                margin: px-to-rem(32) 0 px-to-rem(64);
                padding: 0;
                background: none;
                box-shadow: none;
                min-height: auto;
                line-height: px-to-rem(21);
                color: $clr-dark;
            }
        }
    }

    .saved-addresses {
        @include make-flex(row, stretch, space-between);

        flex-wrap: wrap;
        gap: px-to-rem(20);
        margin: px-to-rem(32) 0 px-to-rem(64);

        @include media-breakpoint-down(lg) {
            @include make-flex(column, stretch, center);

            margin-top: 0;
        }

        &-wrapper {
            &.submitted {
                .address {
                    &-head {
                        padding-bottom: px-to-rem(16);
                    }

                    &-content {
                        margin-top: px-to-rem(16);

                        @include media-breakpoint-down(lg) {
                            margin-top: px-to-rem(23);
                        }
                    }

                    &__address2:not(:empty) {
                        display: inline-block;
                        width: 100%;
                    }

                    &-cta-remove {
                        display: none;
                    }

                    &:not(.selected) {
                        display: none;
                    }

                    &.selected {
                        width: 100%;
                        padding-bottom: px-to-rem(30);
                        background: none;

                        @include media-breakpoint-down(lg) {
                            padding-bottom: px-to-rem(30);
                        }

                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }

        .address {
            width: calc(50% - #{px-to-rem(10)});
            padding: inset(30, 25, 96, 30);
            border: px-to-rem(2) solid $clr-orange;
            border-radius: px-to-rem(20);

            @include media-breakpoint-down(lg) {
                width: 100%;
                margin: 0;
                padding: inset(20, 20, 80, 20);
            }

            &:hover {
                cursor: pointer;
            }

            &-head {
                @include make-flex(row, flex-start, space-between);

                word-break: break-word;
                padding-bottom: px-to-rem(34);
                border-bottom: px-to-rem(1) solid $clr-orange;
                color: $clr-orange;

                @include media-breakpoint-down(lg) {
                    padding-bottom: px-to-rem(20);
                }
            }

            &-icons {
                @include make-flex(row, center, flex-end);

                gap: px-to-rem(20);
                font-size: px-to-rem(24);

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(20);
                }
            }

            &-content {
                @include make-flex(column, flex-start, flex-start);

                margin-top: px-to-rem(31);
                gap: px-to-rem(5);
                line-height: px-to-rem(26);
                font-weight: $font-weight-normal;
                color: $clr-dark;

                @include media-breakpoint-down(lg) {
                    margin-top: px-to-rem(20);
                }
            }

            &__address2:not(:empty) {
                display: inline-block;
                width: 100%;
            }

            &-cta {
                padding: 0;
                background: none;
                border: none;
                color: $clr-orange;
            }

            &__name {
                font-size: px-to-rem(26);
                font-weight: $font-weight-semibold;
                line-height: px-to-rem(32);

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(20);
                    line-height: px-to-rem(24);
                }

            }

            &-wrapper {
                @include make-flex(column, center, flex-start);

                gap: px-to-rem(77);
                height: 100%;
                opacity: 0.5;
                color: $clr-orange;

                @include media-breakpoint-down(lg) {
                    gap: px-to-rem(43);
                }
            }

            &-unavailable {
                position: relative;
                border-color: $clr-darkgrey;

                &:hover {
                    cursor: default;
                }

                &-message {
                    position: absolute;
                    width: 100%;
                    padding: px-to-rem(20);
                    left: 0;
                    bottom: 0;
                    border-radius: px-to-rem(18);
                    border: 1px solid $white;
                    color: $white;
                    background-color: $clr-pomegranate;
                }

                &-text {
                    font-size: px-to-rem(14);
                    font-weight: $font-weight-normal;
                    line-height: px-to-rem(17);
                }

                &-modify {
                    background: none;
                    border: none;
                    padding: 0;
                    color: white;
                    text-decoration: underline;
                    font-size: px-to-rem(14);
                    font-weight: $font-weight-normal;
                    text-align: left;
                }

                .address-head,
                .address-cta {
                    color: $clr-darkgrey;
                    border-color: $clr-darkgrey;
                }
            }

            &.selected {
                position: relative;
                background-color: rgba($clr-orange, 0.1);

                &::before {
                    content: '';
                    position: absolute;
                    left: px-to-rem(-7);
                    top: px-to-rem(-13);
                    height: px-to-rem(25);
                    width: px-to-rem(25);
                    border-radius: px-to-rem(100);
                    background: url('../images/check-mark-multiple-lined.svg') no-repeat $clr-orange center;
                }

                &:hover {
                    cursor: default;
                }

                &.disabled {
                    background: none;

                    &::before {
                        content: none;
                    }
                }
            }

            &.add-address {
                background: none;

                .label {
                    font-size: px-to-rem(14);
                    font-weight: $font-weight-normal;
                    line-height: px-to-rem(17);
                    text-decoration: underline;
                }

                .icon {
                    font-size: px-to-rem(87);
                }
            }
        }

        &.submitted {
            .address {
                width: 100%;
                background: none;

                &::before {
                    content: none;
                }

                &-cta-remove {
                    display: none;
                }

                &:not(.selected) {
                    display: none;
                }
            }
        }
    }

    &-wrapper {
        @include make-flex(row, normal, space-between);

        gap: px-to-rem(20);

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            gap: 0;
        }
    }

    &-title {
        margin: px-to-rem(64) 0 px-to-rem(32);
        font-size: px-to-rem(26);
        line-height: px-to-rem(32);
        font-weight: $font-weight-semibold;
        color: $clr-darkergrey;

        @include media-breakpoint-down(lg) {
            font-size: px-to-rem(20);
            line-height: px-to-rem(24);
        }
    }

    &-notification {
        margin-top: px-to-rem(24);
    }

    &-page {
        .footer {
            background-color: $clr-menugrey;
            color: $clr-dark;

            &__row {
                width: px-to-rem(842);
                margin: px-to-rem(16) auto 0;
                padding-top: 0;
                gap: px-to-rem(20);
                border: none;

                @include media-breakpoint-down(lg) {
                    @include make-flex(column, center, flex-start);

                    width: 100%;
                    gap: px-to-rem(16);
                }
            }

            &__reinsurance {
                position: relative;
                height: px-to-rem(55);
                width: 100%;
                border: px-to-rem(1) solid $white;

                &-payments {
                    width: px-to-rem(94);
                }

                &-icons {
                    @include make-flex(row, center, space-between);

                    height: 100%;
                    gap: px-to-rem(5);

                    img {
                        height: 80%;
                    }

                    .prompt {
                        display: none;
                    }

                    .icon {
                        font-size: px-to-rem(14);

                        &:hover {
                            cursor: pointer;

                            ~ .prompt {
                                position: absolute;
                                display: block;
                                width: 100%;
                                bottom: px-to-rem(40);
                                left: 0;
                                padding: px-to-rem(10);
                                border-radius: px-to-rem(10);
                                color: $clr-darkgrey;
                                font-size: px-to-rem(10);
                                font-weight: $font-weight-normal;
                                box-shadow: $box-shadow;
                                background-color: $white;
                            }
                        }
                    }
                }
            }

            .payment-info {
                padding-top: px-to-rem(64);
                text-align: center;
            }
        }

        .payment-notification {
            margin-top: px-to-rem(32);
        }
    }

    .payment-container {
        .billing-address-block,
        .contact-info-block {
            height: 0;
            visibility: hidden;
        }

        .checkout-title {
            margin: 0 0 px-to-rem(16);
            padding-top: px-to-rem(64);
            border-top: px-to-rem(1) solid $clr-dark;
        }

        .card {
            background: none;
            border: none;

            &-body {
                padding: 0;
            }

            &-head {
                @include make-flex(row, center, space-between);

                @include media-breakpoint-down(lg) {
                    flex-wrap: wrap;
                    gap: px-to-rem(14);
                }
            }

            &-image {
                width: auto;
                height: px-to-rem(26);

                @include media-breakpoint-down(lg) {
                    order: 1;
                }
            }

            &-description {
                text-align: left;
                font-size: px-to-rem(14);
                line-height: px-to-rem(22);
                font-weight: $font-weight-normal;
            }

            .btn {
                @include make-flex(column, stretch, flex-start);

                gap: px-to-rem(14);
                width: 100%;
                padding: px-to-rem(32);
                background-color: $clr-menugrey;
                box-shadow: none;
                border: none;
                color: $clr-btndark;

                @include media-breakpoint-down(lg) {
                    padding: px-to-rem(20);
                    flex-wrap: wrap;
                    gap: px-to-rem(14);
                }

                .title {
                    @include make-flex(row, center, flex-start);

                    gap: px-to-rem(10);
                    font-size: px-to-rem(18);
                    line-height: px-to-rem(22);

                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        order: 2;
                        font-size: px-to-rem(16);
                        line-height: px-to-rem(20);
                    }

                    &-icon {
                        box-sizing: content-box;
                        height: px-to-rem(28);
                        width: px-to-rem(28);
                        border: px-to-rem(1) solid $clr-darkergrey;
                        border-radius: px-to-rem(100);
                        background-color: $white;
                    }

                    &-text {
                        @include media-breakpoint-down(lg) {
                            text-align: left;
                        }
                    }
                }

                &.active {
                    border: px-to-rem(1) solid $clr-borderorange;
                    background-color: $white;
                    color: $clr-borderorange;

                    .title-icon {
                        position: relative;
                        border-color: $clr-borderorange;

                        &::after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            border: px-to-rem(2) solid $white;
                            border-radius: px-to-rem(100);
                            background-color: $clr-borderorange;
                        }
                    }
                }
            }
        }

        .tab-pane {
            margin-top: px-to-rem(16);
        }

        .submit-payment-axepta {
            margin: px-to-rem(64) 0;
            background-color: $clr-darkergrey;

            @include media-breakpoint-down(lg) {
                margin-bottom: px-to-rem(32);
            }
        }

        .condition {
            @include make-flex(row, flex-start, flex-start);

            gap: px-to-rem(10);
            margin: px-to-rem(32) 0;
            flex-wrap: wrap;

            &-input {
                height: px-to-rem(26);
                width: px-to-rem(26);
                margin: 0;
                border-radius: px-to-rem(5);
            }

            &-label {
                width: calc(100% - #{px-to-rem(36)});
                margin: px-to-rem(3) 0 0;
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);

                .link {
                    display: inline-block;
                    margin-top: px-to-rem(12);
                    border-bottom: px-to-rem(1) solid $black;
                    text-decoration: none;

                    &:hover {
                        cursor: pointer;
                        border-color: $clr-orange;
                    }
                }
            }
        }

        .alert-danger {
            margin-bottom: px-to-rem(32);
        }

        .list-unstyled {
            gap: px-to-rem(16);
        }
    }
}
