.content-page {
    min-height: px-to-rem(400);
    color: $clr-darkergrey;
    line-height: px-to-rem(26);

    &__content {
        margin-top: px-to-rem(64);
        padding: 0;

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(32);
        }
    }

    &__sidebar {
        position: relative;
        padding-bottom: sp(5);

        @include media-breakpoint-up(xl) {
            padding: sp(5) px-to-rem(20);
        }

        .best-rates-block {
            position: static;
            float: right;
        }
    }
    

    h1 {
        @include text-size(36, 40, 600);

        color: $clr-orange;
        margin-bottom: sp(5);
    }

    h2, h3, h4, h5, h6, p, ul, ol {
        margin-bottom: sp(4);
    }

    h2, h3, h4 {
        @include text-size(24, 28);

        color: $clr-orange;

        span {
            font-weight: $font-weight-bold;
        }
    }

    p, ol, ul {
        @include text-size(14, 22);

        color: $clr-black;
    }

    b, strong {
        font-weight: $font-weight-semibold;

        h1 &, h2 &, h3 &, h4 &, h5 &, h6 & {
            font-weight: $font-weight-bold;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    ul {
        padding-left: sp(5);

        li {
            list-style-type: disc;
            
            &::marker {
                color: $clr-orange;
            }
        }
    }

    .seperator {
        @include make-flex(row, center, center);

        position: relative;
        margin: sp(5) auto;
        height: 100px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            width: 100%;
            height: px-to-rem(1);
            border-top: px-to-rem(1) solid $clr-menugrey;
        }

        &::after {
            display: block;
            content: '';
            width: 90px;
            height: 90px;
            background-image: url("../images/perso-2.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            z-index: 1;
        }

        img {
            position: relative;
            z-index: 1;
        }
    }
}