.breadcrumb {
    &-item {
        font-weight: $font-weight-normal;
        line-height: px-to-rem(22);

        a {
            color: $clr-orange;
        }
    }
}
