// Layout

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px) !default;
$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 960px,
    xl: 1140px,
    xxl: 1312px) !default;

// Primary Colors

$clr-orange: #EF7D00;
$clr-orange2: #E67E22;
$clr-pomegranate: #E24514;

// Secondary Colors

$clr-black: #000;
$clr-white: #FFF;
$clr-cyan: #69D2C2;
$clr-lightergrey: #F1F1F1;
$clr-lightgrey: #CCC;
$clr-grey: #D6D5D4;
$clr-darkgrey: #706F6F;
$clr-darkergrey: #34383F;
$clr-dark: #171717;
$clr-btndark: #191715;
$clr-green: #00B728;
$clr-yellow: #FFD500;
$clr-overlay: rgba($clr-black, 0.15);
$clr-bordergrey: #868C8E;
$clr-menugrey: #F0F1F0;
$clr-light: #EEE;
$clr-scrollbardark: #1b1b1b;
$clr-borderorange: #EC7703;
$clr-dimorange: #F1813D;
$clr-checkboxgrey: #D9D9D9;
$clr-textareagrey: #D9D9D959;
$clr-darkshade: #3F3939;

// Color Overrides
$body-color: $clr-black !default;
$primary: $clr-orange !default;
$secondary: $clr-darkergrey !default;

// Typography

$base-html-font-size: 16; // Use without unit.
$ls-small: 0.02em;
$ls-light: 0.04em;
$ls-medium: 0.06em;
$line-height-light: 125%;
$line-height-medium: 130%;
$line-height-large: 150%;

// Layer depths

$z-index-carousel: 10;
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-popover: 1025; // less than header
$z-index-popover-mobile: 1025; // less than header
$z-index-fixed: 1030;
$z-index-header: 1035;
$z-index-mobile-menu-overlay: 1050; // .modal-backdrop
$z-index-mobile-menu: 1055; // .modal --> var(--bs-modal-zindex)

// Various component variables

$table-cell-padding-y: px-to-rem(15);
$table-cell-padding-x: px-to-rem(15);
$table-cell-padding-y-sm: px-to-rem(15);
$table-cell-padding-x-sm: px-to-rem(10);
$table-striped-bg: $clr-grey;
$btn-height: px-to-rem(32);

// Shadows

$box-shadow: 0 0 px-to-rem(20) 0 rgba($clr-black, 0.2);
$box-shadow-lg: 0 0 px-to-rem(28) 0 rgba($clr-black, 0.2);
$form-select-indicator-color: $clr-bordergrey;
$form-select-indicator: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.245 5L12 17.5773L0.781 5L0 5.54162L12 19L24 5.53288L23.245 5Z" fill="#868C8E"/></svg>') !default;