/* stylelint-disable */
#tarteaucitronRoot {
  #tarteaucitronAlertBig {
    background-color: $clr-orange !important;
    box-shadow: $box-shadow;

    @include media-breakpoint-down(lg) {
      button:not(:last-of-type) {
        margin-bottom: sp(3) !important;
      }
    }

    .tarteaucitronDeny,
    #tarteaucitronCloseAlert {
      background-color: transparent !important;
      color: $clr-white !important;
      border: solid px-to-rem(2) $clr-white;
      border-radius: $border-radius-sm;
    }

    .tarteaucitronAllow {
      background-color: $clr-darkergrey !important;
      color: $clr-white;
      border: solid px-to-rem(2) $clr-darkergrey;
      border-radius: $border-radius-sm;
    }
  }

  span#tarteaucitronDisclaimerAlert {
    @include media-breakpoint-down(lg) {
      margin-bottom: sp(4) !important;
    }
  }

  #tarteaucitronServices .tarteaucitronMainLine,
  #tarteaucitronServices .tarteaucitronTitle button,
  #tarteaucitronClosePanel,
  #tarteaucitronInfo {
    background-color: $clr-orange !important;
  }

  #tarteaucitronInfo {
    border-color: rgba($clr-black, 0.15);
  }

  button#tarteaucitronSaveButton {
    background-color: $clr-darkergrey !important;
    color: $clr-white;
    border: solid px-to-rem(2) $clr-darkergrey;
    border-radius: $border-radius-sm;
  }


  div#tarteaucitronSave {
    background-color: $clr-white;
  } 

}

#tarteaucitron {
  #tarteaucitronServices {
    #tarteaucitronAllAllowed.tarteaucitronIsSelected,
    #tarteaucitronServices_mandatory .tarteaucitronLine button.tarteaucitronAllow,
    .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
    #tarteaucitronAllDenied.tarteaucitronIsSelected,
    #tarteaucitronAllDenied2.tarteaucitronIsSelected,
    .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
      background-color: $clr-darkergrey !important;
    }

    .tarteaucitronTitle+[id^=tarteaucitronDetails] {
      background-color: $clr-lightgrey !important;
    }
  }
}
/* stylelint-enable */