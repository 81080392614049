// Requied Built-in Modules

@use "sass:map";

// Core

@import './library/all';

// 3rd Party Vendors

@import './thirdParty/bootstrap/bootstrap';
@import "./thirdParty/tarteaucitron.css";
@import "./thirdParty/tarteaucitronCustom";

// Vendor-specific utility mixins

@import './util/cssConstants';
@import './util/utility';

// Complex Components

@import './components/common/all';

// Editorial Content
// @import './components/editorial/banner/banner';
// @import './components/editorial/imageCard/imageCard';
// @import './components/editorial/imageCard/imageCardHeader';
// @import './components/editorial/imageCard/imageCardSlider';
// @import './components/editorial/imageCard/imageCardGallery';

// SFRA Components
// @import './components/product/productTiles';
// @import './components/product/productLineItem';

@import './components/header/header';
@import './components/header/checkoutHeader';
@import './components/footer/footer';
@import './components/footer/checkoutFooter';

// @import './components/header/menu';
// @import './components/header/countrySelector';
// @import './components/search/search';
// @import './components/plp/plp';
// @import './components/cart/cartSummary';
// @import './pages/account/login';

// Page-specific Styles Here

@import './pages/common';
@import './pages/contact';
@import './pages/login';
@import './pages/home';
@import './pages/quote';
@import './pages/account';
@import './pages/checkout';
@import './pages/error';
@import './pages/faq';
@import './pages/price';
@import './pages/article';
@import './pages/content';
@import './pages/orderDetails';
@import './pages/confirmation';
