// Header
$header-height: px-to-rem(64);

.header {
    $parent: &;

    a {
        text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
        padding: inset(27,20,0,20);
    }

    &#{$const-active} {
        + .main-content {
            padding-top: $header-height;
        }
    }

    &__nav {
        position: relative;
        transition: height .3s ease-in-out;
        height: $header-height;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            max-width: px-to-rem(1392);
            height: auto;
            min-height: $header-height;
            padding: px-to-rem(9) px-to-rem(13);
            border: px-to-rem(1) solid $clr-grey;
            border-radius: $border-radius-pill;
        }

        @include media-breakpoint-down(lg) {
            #{$const-active} & {
                position: fixed;
                width: 100%;
                height: 100%;
                background-color: clr(white);
                z-index: $z-index-header;
                padding-bottom: 0;
                overflow: hidden;
            }
        }
    }

    &__nav-inner {
        @include media-breakpoint-up(lg) {
            @include make-flex(row, center, null);
        }

        @include media-breakpoint-down(lg) {
            padding-top: $header-height;
        }
    }

    &__logo {
        width: px-to-rem(122);
        height: px-to-rem(36);

        @include make-flex(column, null, flex-end);

        @include media-breakpoint-up(lg) {
            width: px-to-rem(170);
            min-width: px-to-rem(170);
            height: px-to-rem(46);
            padding-left: px-to-rem(20);            
        }

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: px-to-rem(14);
            left: px-to-rem(50);
        }
    }

    &__logo-image {
        width: 100%;
        height: auto;
        object-fit: contain;

        @include media-breakpoint-down(lg) {
            max-height: px-to-rem(31);
        }
    }

    &__menu-wrap {
        display: none;

        @include media-breakpoint-up(lg) {
            padding: inset(0, 20, 0, 40);
            flex-grow: 1;

            @include make-flex(row, center, space-between);
        }

        @include media-breakpoint-down(lg) {
            #{$const-active} & {
                display: flex;
                flex-direction: column;
                width: calc(100%);
                height: calc(100vh - $header-height);
                overflow-y: auto;
            }
        }
    }

    &__menu-toggler {
        position: absolute;
        top: px-to-rem(20);
        left: px-to-rem(16);
        background: transparent;
        border: none;
        width: px-to-rem(24);
        height: px-to-rem(24);
        padding: 0;
        font-size: px-to-rem(20);

        @include make-flex(row, center, center);

        @include media-breakpoint-up(lg) {
            display: none;
        }

        #{$const-active} & {
            .icon-menu::before {
                content: get-icon(close);
            }
        }
    }

    &__icon-links {
        display: flex;

        @include media-breakpoint-down(lg) {
            #{$const-active} & {
                flex-direction: column;
                flex-grow: 1;
                background-color: $clr-menugrey;
                width: 100%;
                padding: 0 px-to-rem(16);
            }
        }
    }

    &__icon-link {
        @include make-flex(row, center, null);

        padding: px-to-rem(32) 0;

        @include media-breakpoint-up(lg) {
            margin-right: px-to-rem(20);
            padding: 0;

            &:last-child {
                margin: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            &:not(:first-child) {
                border-top: px-to-rem(1) solid clr(white);
            }
        }

        .icon {
            display: flex;
            font-size: px-to-rem(30);
            width: px-to-rem(30);
            height: px-to-rem(30);

            @include media-breakpoint-up(lg) {
                font-size: px-to-rem(40);
                width: px-to-rem(40);
                height: px-to-rem(40);
            }
        }
    }

    &__icon-text {
        line-height: px-to-rem(26);
        margin-left: px-to-rem(16);
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__user-dropdown {
        .user-name {
            margin-left: px-to-rem(16);
            color: clr(darkgrey);

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .dropdown-menu {
            @include media-breakpoint-up(lg) {
                padding: px-to-rem(32);
            }

            li:not(:last-child) {
                .dropdown-item {
                    margin-bottom: px-to-rem(16);
                }
            }
        }

        .dropdown-item {
            padding: 0;
            line-height: px-to-rem(26);
            font-weight: $font-weight-normal;
        }

        @include media-breakpoint-down(lg) {
            #{$const-active} & {
                padding: px-to-rem(32) 0;
                border-top: px-to-rem(1) solid clr(white);

                .dropdown-menu,
                .dropdown-menu.show {
                    display: block;
                    background-color: transparent;
                    border: none;
                    padding: inset(19, 0, 0, 46);
                    box-shadow: none;

                    // override to disable js show-hide styling for mobile
                    position: relative !important;
                    transform: none !important;
                }

                .dropdown-item {
                    padding: 0;
                }
            }
        }
    }

    &__user-toggle {
        @include make-flex(row, center, null);

        &::after {
            display: none;
        }
    }

    &__quote {
        @include media-breakpoint-up(lg) {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            position: absolute;
            top: px-to-rem(14);
            right: px-to-rem(16);
        }
    }

    &__quote-link {
        @include make-flex(row, center, space-between);

        border-radius: $border-radius-pill;
        background-color: clr(darkergrey);
        color: clr(white);
        min-width: px-to-rem(118);
        padding: inset(8, 8, 7, 12);
        font-size: font-size('cta','mobile');
        line-height: px-to-rem(17);

        @include media-breakpoint-up(lg) {
            min-width: px-to-rem(139);
            padding: inset(10, 13, 9, 16);
            font-size: font-size('cta','desktop');
            line-height: px-to-rem(20);
        }

        .count {
            @include make-flex(row, center, center);

            border-radius: $border-radius-pill;
            background-color: clr(pomegranate);
            width: px-to-rem(21);
            height: px-to-rem(21);
            font-size: px-to-rem(12);
            line-height: $line-height-light;
            margin-left: px-to-rem(5);
        }
    }
}

.user-avatar {
    @include make-flex(row, center, center);

    width: px-to-rem(30);
    height: px-to-rem(30);
    text-transform: uppercase;
    background-color: clr(darkergrey);
    color: clr(white);
    border-radius: $border-radius-pill;
    font-size: font-size('cta', 'mobile');
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
        width: px-to-rem(40);
        height: px-to-rem(40);
        font-size: font-size('cta', 'desktop');
    }
}

.menu {
    list-style: none;
    padding: px-to-rem(32) px-to-rem(16);
    margin: 0;

    @include media-breakpoint-up(lg) {
        @include make-flex(row, center, null);

        flex-wrap: wrap;
        padding: 0;
    }

    &__item {
        margin-bottom: px-to-rem(16);

        &:last-child {
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: px-to-rem(20);
        }
    }

    &__link {
        display: block;
        font-weight: $font-weight-normal;
        line-height: px-to-rem(26);
    }
}