$accordion-border-radius: px-to-rem(10);

.accordion {
    --bs-accordion-padding-x: #{px-to-rem(20)};
    --bs-accordion-padding-y: #{px-to-rem(20)};
    --bs-accordion-btn-padding-x: #{px-to-rem(20)};
    --bs-accordion-btn-padding-y: #{px-to-rem(18)};
    --bs-accordion-color: #{$clr-darkergrey};
    --bs-accordion-active-color: #{$clr-darkergrey};
    --bs-accordion-btn-color: #{$clr-darkergrey};
    --bs-accordion-border-radius: 0;
    --bs-accordion-inner-border-radius: 0;
    --bs-accordion-border-width: 0;
    --bs-accordion-bg: none;

    font-weight: $font-weight-normal;

    &-item {
        background-color: $clr-white;

        &:not(:first-of-type),
        &:first-of-type,
        &:last-of-type {
            border: px-to-rem(1) solid $clr-menugrey;
            border-radius: $accordion-border-radius;
        }

        &:not(:last-of-type) {
            margin-bottom: sp(4);
        }
    }

    &-button {
        font-size: font-size('body-lg', 'mobile');
        font-weight: $font-weight-semibold;
        line-height: px-to-rem(22);
        word-break: break-word;
        color: $clr-orange;

        @include media-breakpoint-up(lg) {
            font-size: font-size('body-lg', 'desktop');
        }

        &:focus {
            box-shadow: none;
        }

        &:not(.collapsed) {
            background: none;
            color: $clr-orange;
        }
    }

    &-body {
        padding-top: 0;
        font-size: font-size('body-lg', 'mobile');
        word-break: break-word;

        p {
            color: $black;
        }

        ul {
            padding-left: sp(5);

            li {
                list-style-type: disc;
            }
        }

        a,
        li a,
        p a {
            text-decoration: underline;
        }
    }
}