input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
select {
    min-height: px-to-rem(50);
}

.form-group {
    margin: sp(5) 0;

    &.required {
        .form-label {
            display: flex;

            &::after {
                content: "*";
                margin-left: px-to-rem(4);
            }
        }
    }

    .recaptcha-error {
        font-size: font-size('body-sm', 'mobile');
        color: $clr-pomegranate;
        padding-top: px-to-rem(4);
    }

    // makes red border around the recaptcha widget on error
    .g-recaptcha:has(+ .recaptcha-error:not(.d-none)) > div {
        border: px-to-rem(1) solid $clr-pomegranate;
    }

    &--cta {
        @include make-flex(row, center, space-between);

        margin-top: px-to-rem(47);
        margin-bottom: 0;
    
        @include media-breakpoint-down(lg) {
            margin-top: sp(6);
            flex-wrap: wrap;

            .btn-submit {
                order: -1;
            }

            .btn-cancel {
                margin-top: sp(5);
            }
        }
    }

    &-row {
        @include media-breakpoint-up (lg) {
            @include make-flex(row, flex-start, null);
            
            .form-group {
                flex-grow: 1;

                &:first-child {
                    max-width: px-to-rem(209);
                    margin-right: px-to-rem(21);
                }
            }
        }
    }
}

.form-label {
    font-weight: $font-weight-normal;
    @include media-breakpoint-up (lg) {
        font-size: font-size('body-lg', 'desktop');
    }
}

.form-control,
.form-select,
.form-check-input {
    box-shadow: none;
    outline: none;

    &:focus {
        box-shadow: none;
    }

    &.is-invalid,
    &.is-valid {
        padding-right: $input-padding-x;

        &:not(.form-select) {
            background-image: none;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

.form-select {
    padding-right: px-to-rem(40);

    // overrides valid/invalid case styling
    background-size: 24px !important; 
    background-position: right px-to-rem(16) center !important; 

    --bs-form-select-bg-icon: none !important;
}

.form-check {
    min-height: px-to-rem(40);
    margin-bottom: px-to-rem(13);
    padding-left: $form-check-input-width;

    &-label {
        margin: px-to-rem(10) 0 0 px-to-rem(16);
    }

    &-input {
        padding: 0;

        .form-check & {
            margin-left: #{-$form-check-input-width};
            margin-top: 0;
        }

        &:checked {
            box-shadow: inset 0 0 0 px-to-rem(2) var(--bs-body-bg);
        }
    
        &[type="radio"] {
            width: px-to-rem(29);
            min-height: px-to-rem(29);

            + .form-check-label {
                margin-top: px-to-rem(4);
            }
        }

        &.is-invalid,
        &.is-invalid + .form-check-label {
            color: $input-color;
        }

        &.is-invalid:checked {
            background-color: $clr-orange;
            border-color: $clr-orange;
            box-shadow: inset 0 0 0 px-to-rem(2) var(--bs-body-bg);
        }
    }
}

.btn-check {
    + label.btn {
        border: px-to-rem(1) solid $clr-bordergrey;
        color: $clr-darkgrey;
        min-height: px-to-rem(35);
        font-size: font-size('body-sm', 'mobile');
        font-weight: $font-weight-normal;
        padding: px-to-rem(9) px-to-rem(14);
        box-shadow: none;
        border-radius: $border-radius-sm;


        &:not(:last-of-type) {
            margin-right: px-to-rem(16);
        }

        &:hover,
        &:focus {
            border-color: $clr-bordergrey;
        }

        &.btn-primary {
            border-color: $clr-orange;
        }
    }

    &:checked {
        + label.btn {
            color: $clr-darkergrey;
            box-shadow: inset 0 0 0 px-to-rem(1) $clr-darkergrey;

            &:hover {
                border-color: $clr-darkergrey;
            }
        }
    }

    &.is-invalid + label.btn {
        border-color: var(--bs-form-invalid-color);
    }
}

input[type="password"] {
    font: small-caption;
}

.form-text {
    color: $clr-bordergrey;
    margin-top: sp(3);
    line-height: px-to-rem(18);
    font-weight: $font-weight-normal;
}

.invalid-feedback {
    margin-top: sp(3);
    
    .form-check & {
        margin-left: sp(4);
    }
}

.password-helper {
    margin-top: sp(3);
    color: var(--bs-form-invalid-color);
    font-size: font-size('body-sm','mobile');

    &__item {
        display: none;

        &::before {
            content: '-';
            margin-right: sp(2);
        }
    }
}

.result {
    @include make-flex(row, flex-start, flex-start);

    max-height: px-to-rem(152);
    gap: sp(4);
    overflow-y: scroll;
    line-height: px-to-rem(26);
    flex-wrap: wrap;
    flex-grow: 1;
    text-transform: uppercase;

    @include custom-scrollbars;
    
    &-container {
        @include make-flex(row, flex-start, flex-start);

        max-height: px-to-rem(184);
        width: 100%;
        position: absolute;
        top: px-to-rem(90);
        padding: inset(16, 5, 16, 20);
        background-color: $white;
        box-shadow: $box-shadow;
        gap: sp(4);
        border-radius: px-to-rem(10);
        font-size: font-size('body-sm','mobile');
        z-index: 10;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    .postal-code-suggestion {
        width: 100%;
        cursor: pointer;
        padding-right: px-to-rem(11);

        .highlighted {
            font-weight: $font-weight-semibold;
        }
    }
}

.phone-field-container {
    position: relative;
    min-height: px-to-rem(50);

    &::before {
        content: '0';
        position: absolute;
        width: px-to-rem(10);
        height: px-to-rem(16);
        left: px-to-rem(16);
        top: 50%;
        margin-top: px-to-rem(-10);
        font-weight: $font-weight-normal;
        font-size: font-size('body-sm','mobile');
        text-align: right;
    }

    input {
        padding-left: px-to-rem(25);
    }
}