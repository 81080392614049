.radio {
    &-items {
        @include make-flex(row, stretch, center);

        gap: px-to-rem(21);
        margin-top: px-to-rem(32);

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(16);
            flex-wrap: wrap;
            gap: px-to-rem(16);
        }
    }

    &-item {
        width: 50%;
        border-radius: px-to-rem(20);
        background-color: rgba($clr-darkgrey, 0.1);

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
        
        &-label {
            width: 100%;
            padding: inset(29, 30, 44, 32);

            @include media-breakpoint-down(lg) {
                padding: inset(19, 37, 27, 20);
            }
        }
    
        &-head {
            @include make-flex(row, flex-start, flex-start);

            gap: px-to-rem(8);
            padding-bottom: px-to-rem(18);
            border-bottom: px-to-rem(1) solid $clr-darkergrey;

            @include media-breakpoint-down(lg) {
                padding-bottom: px-to-rem(22);
            }
        }
    
        &-info {
            line-height: normal;
        }
    
        &__input {
            height: px-to-rem(29);
            min-width: px-to-rem(29);
            appearance: none;
            border: px-to-rem(1) solid $clr-darkergrey;
            border-radius: 50%;
            background-color: $white;
    
            &:checked {
                box-shadow: 0 0 0 px-to-rem(1) $clr-orange;
                background-color: $clr-orange;
                border: px-to-rem(2) solid $white;
            }
        }
    
        &__title {
            padding-bottom: px-to-rem(3);
            font-size: px-to-rem(26);
            font-weight: $font-weight-semibold;

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(22);
            }
        }
    
        &__subtitle {
            gap: px-to-rem(5);
            line-height: px-to-rem(32);
            font-size: px-to-rem(18);
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(lg) {
                height: auto;
                font-size: px-to-rem(16);
                line-height: px-to-rem(27);
            }
        }
    
        &.checked {
            border: px-to-rem(2) solid $clr-borderorange;
            background-color: $white;
    
            .radio-item__title,
            .radio-item__subtitle {
                color: $clr-orange;
            }
        }
    }
}

.quote {
    color: $clr-dark;

    &-head {
        color: $clr-darkergrey;

        &-btn {
            padding: 0;
            background-color: $clr-darkergrey;
            line-height: px-to-rem(12);
            border: none;
        }
    }

    &-reminder {
        @include make-flex(row, center, center);

        gap: px-to-rem(10);
        margin-top: px-to-rem(64);
        font-weight: $font-weight-semibold;
        font-size: px-to-rem(14);

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(32);
            font-size: px-to-rem(12);
        }
    }

    &-title {
        margin: px-to-rem(32) 0 px-to-rem(16);
        text-align: center;
        font-size: px-to-rem(66);
        line-height: px-to-rem(67);
        font-weight: $font-weight-semibold;

        @include media-breakpoint-down(lg) {
            padding: 0 px-to-rem(18);
            font-size: px-to-rem(36);
            line-height: px-to-rem(38);
        }

        .prompt {
            &.title-prompt {
                @include media-breakpoint-down(lg) {
                    right: px-to-rem(-50);
                }
            }

            &-container {
                .info-icon {
                    position: absolute;
                }
            } 
        }  

        span {
            color: $clr-orange;
            font-weight: $font-weight-bold;
        }
    }

    &-subtitle {
        margin-top: px-to-rem(32);
        font-size: px-to-rem(26);
        font-weight: $font-weight-semibold;
        text-align: center;

        @include media-breakpoint-down(lg) {
            font-size: px-to-rem(22);
            padding: 0 px-to-rem(18);
        }
    }

    &-button-container {
        border-radius: px-to-rem(20);
        background-color: $clr-darkergrey;
        font-weight: $font-weight-normal;
        color: $white;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        .icon {
            font-size: px-to-rem(20);
            color: $white;
        }
    }

    &-buttons {
        @include make-flex(row, center, center);

        gap: px-to-rem(20);
        margin-bottom: px-to-rem(32);

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            gap: px-to-rem(18);
        }
    }

    &-postalcode {
        @include make-flex(row, center, space-between);

        width: px-to-rem(272);
        padding: px-to-rem(16) px-to-rem(15);

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    &-quantity {
        @include make-flex(row, center, space-between);

        width: px-to-rem(270);
        padding: inset(13, 15, 15, 16);

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        &-input {
            max-width: px-to-rem(120);
            width: px-to-rem(53);
            border: none;
            background-color: $clr-darkergrey;
            font-weight: $font-weight-normal;
            color: $white;
            -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
            text-align: right;
            padding: 0;

            &::-webkit-outer-spin-button, /* stylelint-disable-line property-no-vendor-prefix */
            &::-webkit-inner-spin-button { /* stylelint-disable-line property-no-vendor-prefix */
                -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
            }
        }

        .input-container {
            margin-right: px-to-rem(14);
        }
    }

    &-description {
        padding-top: px-to-rem(18);
        font-size: px-to-rem(14);
        font-weight: $font-weight-normal;
        line-height: px-to-rem(22);
        color: $clr-dark;

        @include media-breakpoint-down(lg) {
            padding-top: px-to-rem(16);
            font-size: px-to-rem(12);
        }

        li {
            padding-left: px-to-rem(20);
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: px-to-rem(12);
                height: px-to-rem(22);
                background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20512%20512'%3E%3Cpath%20fill%3D'%23ef7d00'%20d%3D'M173.9%20439.4l-166.4-166.4c-10-10-10-26.2%200-36.2l36.2-36.2c10-10%2026.2-10%2036.2%200L192%20312.7%20432.1%2072.6c10-10%2026.2-10%2036.2%200l36.2%2036.2c10%2010%2010%2026.2%200%2036.2l-294.4%20294.4c-10%2010-26.2%2010-36.2%200z'%2F%3E%3C%2Fsvg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
    }

    &-dm {
        &-label {
            padding-bottom: px-to-rem(33);
        }

        &-head {
            gap: px-to-rem(13);
            padding: 0;
            border: none;
        }

        &__description {
            padding-top: px-to-rem(7);
            font-size: px-to-rem(14);
            font-weight: $font-weight-normal;
            line-height: px-to-rem(22);

            @include media-breakpoint-down(lg) {
                padding-top: px-to-rem(5);
            }
        }
    }

    &-delivery {
        .quote-description {
            padding-top: px-to-rem(7);
            text-align: center;
            line-height: normal;

            @include media-breakpoint-down(lg) {
                padding-top: px-to-rem(8);
                line-height: px-to-rem(20);
            }
        }

        .quote-subtitle {
            .icon {
                &:hover {
                    ~ .prompt {
                        top: px-to-rem(-60);
                        right: px-to-rem(-35);
            
                        @include media-breakpoint-down(lg) {
                            top: px-to-rem(-63);
                            right: px-to-rem(-35);
                        }
                    }
                }
            }
        }    
    }

    &-error {
        padding-top: px-to-rem(7);
        font-size: px-to-rem(14);
        font-weight: $font-weight-semibold;
        line-height: px-to-rem(17);
        text-align: center;
        color: $clr-pomegranate;
    }

    .info-icon {
        position: relative;
        z-index: 1;

        &:hover {
            cursor: pointer;

            + .prompt {
                display: block;
                position: absolute;
                top: px-to-rem(-64);
                left: px-to-rem(-27);
                padding: 0;
                box-shadow: none;
                background: none;
        
                @include media-breakpoint-down(lg) {
                    width: auto;
                    left: auto;
                    top: px-to-rem(-68);
                    right: px-to-rem(-50.5);
                }
        
            }
        }
    }

    .prompt {
        display: none;

        &-container {
            position: relative;

            .icon {
                font-size: px-to-rem(15);
            }
        }

        &-text {
            width: px-to-rem(217);
            color: $clr-darkgrey;
            font-size: px-to-rem(10);
            font-weight: $font-weight-normal;
            line-height: normal;
            text-align: left;
            position: absolute;
            left: px-to-rem(20);
            top: px-to-rem(18);
        }
    }

    .coupon {
        &-container {
            margin-bottom: px-to-rem(64);

            .applied-coupon {
                @include make-flex(row, center, center);
            }
        }

        &-form {
            @include make-flex(row, stretch, center);

            flex-wrap: wrap;
            height: px-to-rem(51);
            margin-top: px-to-rem(32);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(22);
            }

            &.error {
                .coupon-input {
                    color: $clr-pomegranate;
                    border-color: $clr-pomegranate;

                    &::placeholder {
                        color: $clr-pomegranate;
                    }
                }

                .coupon-submit {
                    border-color: $clr-pomegranate;
                }
            }
        }

        &-input {
            width: px-to-rem(213);
            padding-left: px-to-rem(20);
            border-top-left-radius: px-to-rem(10);
            border-bottom-left-radius: px-to-rem(10);
            border: px-to-rem(1) solid $clr-darkergrey;
            border-right: none;
            color: $clr-darkergrey;

            @include media-breakpoint-down(lg) {
                width: calc(100% - #{px-to-rem(64)});
            }

            &-container {
                @include make-flex(row, stretch, center);

                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }

            &::placeholder {
                font-weight: $font-weight-medium;
                line-height: normal;
                color: $clr-darkgrey;
            }

            &:focus {
                outline: none;
            }
        }

        &-submit {
            width: px-to-rem(96);
            padding: 0 px-to-rem(17);
            border-top-right-radius: px-to-rem(10);
            border-bottom-right-radius: px-to-rem(10);
            border: px-to-rem(1) solid $clr-darkergrey;
            border-left: none;
            background-color: $clr-orange;
            font-weight: $font-weight-semibold;
            color: $white;

            @include media-breakpoint-down(lg) {
                width: auto;
            }
        }

        &-error {
            width: 100%;
            padding-top: px-to-rem(15);
            font-size: px-to-rem(14);
            font-weight: $font-weight-normal;
            line-height: px-to-rem(18);
            color: $clr-pomegranate;
            text-align: center;

            @include media-breakpoint-down(lg) {
                text-align: left;
            }

            &-msg {
                display: inline-block;
                width: px-to-rem(309);
                text-align: left;

                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }
        }

        &-code {
            &-container {
                @include make-flex(row, center, center);

                gap: px-to-rem(10);
                margin-top: px-to-rem(32);
                padding: px-to-rem(20);
                background-color: $clr-grey;
                border-radius: px-to-rem(10);
                width: fit-content;
                color: $clr-darkgrey;
                font-weight: $font-weight-medium;
                
                @include media-breakpoint-down(lg) {
                    margin-top: px-to-rem(22);
                }
            }

            &-remove {
                padding: 0;
                border: none;
                background: none;

                .icon {
                    font-size: px-to-rem(13);
                    color: $clr-darkgrey;
                }
            }
        }
    }

    .basket {
        &-summary {
            @include make-flex(row, flex-start, center);

            min-height: px-to-rem(123);
            padding: px-to-rem(17) 0 px-to-rem(20);
            position: sticky;
            bottom: px-to-rem(20);
            margin: px-to-rem(20);
            border-radius: px-to-rem(20);
            background-color: $clr-orange;
            color: $white;
            transition: opacity .3s ease-in-out, transform .3s ease-in-out;
            z-index: 1;

            @include media-breakpoint-down(lg) {
                bottom: 0;
                border-radius: 0;
                margin: 0;
                padding: px-to-rem(23) px-to-rem(16) px-to-rem(30);
                justify-content: flex-start;
            }

            &.empty {
                opacity: 0;
                transform: translateY(px-to-rem(260));
                z-index: -1;
            }
        }

        &-item {
            @include make-flex(row, flex-start, space-between);

            height: 100%;
            gap: px-to-rem(36);
            padding: inset(0, 32, 12, 44);
            border-right: px-to-rem(1) solid $white;
            opacity: 0.7;

            @include media-breakpoint-down(lg) {
                display: none;
                border-right: none;
            }

            &:last-child {
                border: none
            }

            &-head {
                @include make-flex(column, flex-start, flex-start);

                gap: px-to-rem(4);
                margin-top: px-to-rem(10);

                @include media-breakpoint-down(lg) {
                    @include make-flex(row, flex-start, space-between);

                    width: 100%;
                    flex-wrap: wrap;
                }

                .coupon-discount {
                    display: none;
                    font-size: px-to-rem(14);
                }
            }

            &__name {
                font-weight: $font-weight-semibold;
                font-size: px-to-rem(14);

                @include media-breakpoint-down(lg) {
                    order: 1;
                    min-width: 50%;
                }
            }

            &__price {
                font-weight: $font-weight-bold;
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);

                @include media-breakpoint-down(lg) {
                    order: 3;
                    font-size: px-to-rem(16);
                    line-height: px-to-rem(20);
                }

                .ttc {
                    font-size: px-to-rem(12);
                    line-height: px-to-rem(15);

                    @include media-breakpoint-down(lg) {
                        font-size: px-to-rem(16);
                        line-height: px-to-rem(20);
                    }
                }
            }

            &__details {
                font-size: px-to-rem(14);
                font-weight: $font-weight-normal;
                line-height: px-to-rem(18);

                @include media-breakpoint-down(lg) {
                    width: 100%;
                    order: 4;
                }
            }

            &__show-details {
                padding: 0;
                font-size: px-to-rem(14);
                font-weight: $font-weight-normal;
                line-height: px-to-rem(17);
                background: none;
                color: $white;
                border: none;
                text-decoration: underline;

                @include media-breakpoint-down(lg) {
                    order: 2;
                }
            }

            &__submit {
                margin-top: px-to-rem(8);
                padding: px-to-rem(12) px-to-rem(18);
                font-size: px-to-rem(14);
                font-weight: $font-weight-semibold;
                background-color: $clr-darkergrey;
                border-radius: px-to-rem(10);
                border: none;
                visibility: hidden;
                color: $white;
                text-decoration: none;
            }

            &.checked {
                opacity: 1;

                @include media-breakpoint-down(lg) {
                    @include make-flex(column, flex-start, flex-start);

                    width: 100%;
                    padding: 0;
                    gap: px-to-rem(18);
                }

                .basket-item__submit {
                    visibility: visible;

                    @include media-breakpoint-down(lg) {
                        padding: px-to-rem(9) px-to-rem(18);
                    }
                }

                .coupon-discount {
                    display: block;

                    @include media-breakpoint-down(lg) {
                        order: 4;
                    }
                }
            }
        }
    }
}
