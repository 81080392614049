.section-order-details {
    .section-account__header {
        margin-bottom: sp(6);
    }

    .card.order-product-summary,
    .card.checkout-order-total-summary {
        .card-body {
            padding: 0;
        }
    }
}

.card {
    &.card-order-details {
        border-color: $clr-orange;
        padding: px-to-rem(30);
        margin-bottom: px-to-rem(20);

        .card-header {
            padding: 0 0 sp(4);
            margin-bottom: sp(4);
            border-radius: 0;
            background: none;
            border-bottom-color: $clr-orange;
            min-height: px-to-rem(32);
        }

        .card-title {
            margin: 0;
            color: $clr-orange;
            font-size: font-size('h3', 'mobile');
            font-weight: $font-weight-semibold;
            line-height: px-to-rem(26);

            @include media-breakpoint-up(lg) {
                font-size: font-size('h3', 'desktop');
                line-height: px-to-rem(32);
            }
        }

        .card-body {
            padding: 0;
            font-weight: $font-weight-normal;

            .card-row:first-child {
                padding-top: 0;
            }

            .summary-section-label {
                font-size: font-size('h5', 'mobile');
                line-height: px-to-rem(22);
                font-weight: $font-weight-semibold;
                color: $clr-darkergrey;
            }

            .summary-details {
                color: $clr-dark;
            }

            .billing-addr-label {
                display: block;
                margin-top: sp(5);
            }

            .address1,
            .shipping-phone,
            .order-summary-phone,
            .order-summary-email {
                display: inline-block;
                margin-top: sp(3);
            }
        }
    }

    &.order-product-summary,
    &.checkout-order-total-summary {
        margin-bottom: px-to-rem(20);
        border-color: $clr-orange;

        .card-body {
            padding: px-to-rem(30);
            font-weight: $font-weight-normal;
        }

        h3:first-of-type {
            margin: 0;
            color: $clr-orange;
            font-size: font-size('h3', 'mobile');
            font-weight: $font-weight-semibold;
            line-height: px-to-rem(26);
            border-bottom: px-to-rem(1) solid $clr-orange;
            padding-bottom: sp(4);

            @include media-breakpoint-up(lg) {
                font-size: font-size('h3', 'desktop');
                line-height: px-to-rem(32);
            }
        }

        .leading-lines {
            margin: 0;
            border-bottom: px-to-rem(1) solid $card-border-color;
            padding: sp(4) 0;

            p {
                margin: 0;
            }
        }

        .start-lines {
            padding: 0;
            color: $clr-dark;
        }

        .end-lines {
            padding: 0;
            text-align: right;
            font-weight: $font-weight-bold;
            color: $clr-dark;
        }

        .grand-total {
            font-weight: $font-weight-bold;
        }
    }

    &.checkout-order-total-summary {
        margin-top: px-to-rem(60);
        border: none;
        background-color: $clr-menugrey;

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(40);
        }

        .leading-lines {
            border-color: rgba($clr-black, .2);

            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }

    }

    &.order-product-summary {
        .line-item-total-price .leading-lines {
            border: none;
            padding-bottom: 0;
            font-weight: $font-weight-bold;
        }
    }

    &.card-order-details-invoice {
        margin-bottom: px-to-rem(40);

        .card-body .card-row:last-child {
            .card-row__label {
                font-weight: $font-weight-bold;
            }
        }

        ~ .card-order-invoices {
            border: px-to-rem(1) $clr-orange solid;
            padding: px-to-rem(30);
            margin-bottom: px-to-rem(20);

            .card-body .card-row:not(:first-child) {
                display: flex;
                padding: 0;
            }

            ~ .card-expand-btn {
                display: none;
            }
        }
    }

    .card-row,
    .leading-lines {
        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;

            [class^="col-"] {
                width: 100%;
            }

            .end-lines {
                text-align: left;
            }
        }
    }
}

.dl-invoice {
    text-decoration: none;
    font-weight: $font-weight-semibold;
    line-height: px-to-rem(20);
    color: $clr-dark;

    &:hover {
        text-decoration: none;
    }

    .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: px-to-rem(21);
        height: px-to-rem(21);
        transform: rotate(90deg);
    }
}