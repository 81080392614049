.homepage {
    .banner {
        display: flex;
        position: relative;
        margin: px-to-rem(65) 0 px-to-rem(32);

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            margin: 0 0 px-to-rem(148);
        }

        &__first-image {
            height: px-to-rem(435);
            width: 60.37%;
            z-index: 10;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        &__second-image {
            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            img {
                height: px-to-rem(435);
                width: auto;
                position: absolute;
                right: 0;
                
                @include media-breakpoint-down(lg) {
                    height: auto;
                    width: 100%;
                    position: static;
                }
            }
        }

        &__content {
            height: 100%;
            width: 55%;
            position: absolute;
            padding: px-to-rem(32);
            z-index: 11;

            @include media-breakpoint-down(lg) {
                position: static;
                min-width: 100%;
                padding: px-to-rem(17) px-to-rem(20) px-to-rem(16) px-to-rem(16);
                border-radius: px-to-rem(20) px-to-rem(20) 0 0;
                background-color: $clr-orange;
            }
        }

        &__title {
            color: $white;
            font-size: px-to-rem(46);
            font-weight: $font-weight-normal;
            line-height: normal;
            word-wrap: break-word;

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(30);
            }

            span, h1, h2, h3, h4 {
                font-size: px-to-rem(46);
                font-weight: $font-weight-bold;
                line-height: px-to-rem(51);

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(30);
                    line-height: px-to-rem(36);
                }
            }
        }

        &__subtitle {
            padding-top: px-to-rem(16);
            color: $clr-darkergrey;
            font-size: px-to-rem(26);
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: normal;
            word-wrap: break-word;

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(22);
            }
        }

        .postal-code {
            &-form {
                @include make-flex(row, flex-end, flex-start);

                max-width: 90%;
                position: absolute;
                bottom: px-to-rem(32);
                gap: px-to-rem(16);

                @include media-breakpoint-down(lg) {
                    bottom: px-to-rem(-84.4);
                    min-width: 100%;
                    left: 0;
                    flex-wrap: wrap;
                    gap: px-to-rem(8);
                }

                .result-container {
                    top: px-to-rem(56);
                }
            }

            &-input {
                width: px-to-rem(321);
                height: px-to-rem(50.7);
                border-radius: px-to-rem(10);
                border: px-to-rem(1) solid $clr-darkergrey;
                font-weight: $font-weight-medium;
                text-align: center;
                background-color: $white;

                &::placeholder {
                    color: $clr-darkergrey;
                    font-size: px-to-rem(16);
                }

                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }

            &-submit {
                width: px-to-rem(202);
                height: px-to-rem(52);
                font-weight: $font-weight-semibold;
                border-radius: px-to-rem(11);
                background-color: $clr-darkergrey;
                border: none;
                color: $white;

                @include media-breakpoint-down(lg) {
                    height: px-to-rem(50.7);
                    width: 100%;
                    margin: 0 px-to-rem(20) 0 px-to-rem(17);
                }
            }
        }

        p {
            margin: 0;
        }
    }

    .toast-notification {
        margin-bottom: px-to-rem(32);

        @include media-breakpoint-up(lg) {
            margin-top: px-to-rem(32);
        }

        + .banner {
            margin-top: px-to-rem(64);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(32);
            }
        }
    }

    .review-block {
        position: relative;
        margin-top: px-to-rem(64);
        background-color: $clr-orange;

        &__wrapper {
            @include make-flex(row, flex-start, space-between);

            padding-top: px-to-rem(64);

            @include media-breakpoint-down(lg) {
                padding-top: px-to-rem(32);
            }
        }

        &__logo {
            @include media-breakpoint-down(lg) {
                position: absolute;
                width: px-to-rem(55);
                right: px-to-rem(27);
                top: px-to-rem(-28);
            }
        }

        &__title {
            @include text-size(46, 56, 400);

            width: px-to-rem(800);
            color: $clr-darkergrey;

            .highlighted {
                font-weight: $font-weight-bold;
                color: $white;
            }

            .line {
                display: inline-block;
                width: 100%;
            }

            @include media-breakpoint-down(lg) {
                @include text-size(30, 36, 400);

                .highlighted {
                    font-weight: $font-weight-bold;
                }

                .line {
                    display: inline;
                }
            }
        }

        &__rating {
            @include make-flex(row, center, flex-start);

            margin-top: px-to-rem(8);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(16);
            }

            .rate {
                @include text-size(26, 32, 600);

                color: $white;

                @include media-breakpoint-down(lg) {
                    @include text-size(16, 20, 700);
                }
            }

            .stars-container {
                @include make-flex(row, center, flex-start);

                gap: px-to-rem(4);
                margin: inset(0, 6, 0, 9);

                @include media-breakpoint-down(lg) {
                    gap: px-to-rem(2);
                    margin: inset(0, 8, 0, 4);
                }

                svg {
                    height: px-to-rem(16);
                    width: px-to-rem(16);

                    @include media-breakpoint-down(lg) {
                        height: px-to-rem(10);
                        width: px-to-rem(10);
                    }
                }
            }

            .count {
                @include text-size(12, 15, 400);

                margin-top: px-to-rem(6);
                color: $white;

                @include media-breakpoint-down(lg) {
                    margin: 0;
                }
            }
        }

        &__comments {
            @include make-flex(row, stretch, space-between);

            gap: px-to-rem(20);

            @include media-breakpoint-down(lg) {
                width: calc(100% + #{px-to-rem(16)});
                padding-right: px-to-rem(16);
                overflow-x: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
            
            .review {
                @include make-flex(column, flex-start, space-between);

                position: relative;
                width: 50%;
                margin: px-to-rem(32) 0;
                padding: inset(30, 32, 32, 30);
                border-radius: px-to-rem(20);
                background-color: $white;
                color: $clr-darkergrey;

                @include media-breakpoint-down(lg) {
                    min-width: px-to-rem(313);
                    width: auto;
                    margin-top: px-to-rem(46);
                    padding: px-to-rem(20) px-to-rem(26);
                }

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    top: px-to-rem(-12);
                    left: px-to-rem(33);
                    display: block;
                    height: px-to-rem(33);
                    width: px-to-rem(22);
                    background: url('../images/review-card-comma.svg') no-repeat;

                    @include media-breakpoint-down(lg) {
                        top: px-to-rem(-18);
                        left: px-to-rem(25);
                        background-image: url('../images/review-card-comma-mobile.svg');
                    }            
                }

                &::after {
                    left: px-to-rem(62);

                    @include media-breakpoint-down(lg) {
                        left: px-to-rem(50);
                    }    
                }

                &__comment {
                    @include text-size(18, 22, 400);

                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $black;
                    word-wrap: break-word;

                    @include media-breakpoint-down(lg) {
                        @include text-size(14, 17, 250);
                    }
                }

                &__rate {
                    @include make-flex(row, center, flex-start);
                    @include text-size(20, 24, 400);

                    gap: px-to-rem(10);
                    padding-top: px-to-rem(17);

                    @include media-breakpoint-down(lg) {
                        @include text-size(18, 22, 400);

                        gap: px-to-rem(5);
                    }

                    .stars-container {
                        @include make-flex(row, center, flex-start);

                        gap: px-to-rem(3);

                        svg {
                            height: px-to-rem(16);
                            width: px-to-rem(16);

                            @include media-breakpoint-down(lg) {
                                height: px-to-rem(14);
                                width: px-to-rem(14);
                            }
                        }
                    }
                }

                &__sender {
                    @include text-size(16, 20, 400);

                    margin-top: px-to-rem(4);
                    font-style: italic;

                    @include media-breakpoint-down(lg) {
                        @include text-size(14, 17, 400);

                        margin: 0;
                    }
                }
            }
        }

        &__link {
            @include make-flex(row, center, flex-start);
            @include text-size(16, 20, 600);
    
            gap: px-to-rem(5);
            padding-bottom: px-to-rem(90);
            text-decoration: none;
            color: $white;

            @include media-breakpoint-down(lg) {
                @include text-size(12, 15, 600);

                padding-bottom: px-to-rem(33);
            }
        }
    }
}