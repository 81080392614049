.newsletter {
    position: relative;
    z-index: 1;
    background-color: $clr-orange;
    border-radius: $border-radius;
    margin: sp(6) 0;
    padding: sp(4);
    color: $clr-white;

    @include media-breakpoint-up(lg) {
        @include make-flex(row, center, flex-start);

        gap: sp(4);
    }

    @include media-breakpoint-up(xl) {
        gap: px-to-rem(87);
    }

    &__info {
        flex-grow: 1;
    }

    &__title {
        font-size: font-size('h3', 'desktop');
        line-height: px-to-rem(32);
        margin-bottom: 0;
        word-wrap: break-word;

        span {
            font-weight: $font-weight-bold;
        }
    }

    &__text {
        font-size: font-size('body-sm', 'mobile');
        word-wrap: break-word;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0
        }

        .hf-tooltip {
            margin-left: sp(3);

            .icon {
                color: $clr-white;
            }
        }

        .hf-tooltip-text {
            @include media-breakpoint-down(lg) {
              min-width: px-to-rem(200);
            }
        }
    }

    .newsletter-form {
        @include media-breakpoint-up(lg) {
            @include make-flex(row, center, flex-start);

            gap: px-to-rem(15);

            .btn-secondary {
                min-width: px-to-rem(148);
            }
        }
    }

    .form-group {
        margin: px-to-rem(15) 0;
        position: relative;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }

    .form-control {
        min-height: px-to-rem(52);
        font-size: font-size('body-lg', 'mobile');
        font-weight: $font-weight-medium;
        color: $clr-darkgrey;
        border-color: $clr-darkergrey;

        &::placeholder {
            color: $clr-darkgrey;
        }

        &.is-invalid {
            + .invalid-feedback:not(:empty) {
                font-size: font-size('body-sm', 'mobile');
                color: $clr-white;
                padding: px-to-rem(10);
                box-shadow: $box-shadow;
                background-color: $clr-pomegranate;
                border: px-to-rem(1) solid $clr-white;
                border-radius: $border-radius;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: px-to-rem(62);

                + .newsletter-message:not(.d-none) {
                    display: none;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            min-width: px-to-rem(321);

            &.is-invalid {
                + .invalid-feedback:not(:empty) {
                    top: px-to-rem(62);
                    bottom: auto;
                    margin: 0;
                }
            }
        }
    }

    &-message {
        font-size: font-size('body-sm', 'mobile');
        position: absolute;
        width: 100%;
        left: 0;
        bottom: px-to-rem(62);
        color: $clr-white;
        padding: px-to-rem(10);
        box-shadow: $box-shadow;
        background-color: $clr-green;
        border: px-to-rem(1) solid $clr-white;
        border-radius: $border-radius;

        @include media-breakpoint-up(lg) {
            top: px-to-rem(62);
            bottom: auto;
        }
    }
}