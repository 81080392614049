.section-login {
    .section__title {
        @include media-breakpoint-up(lg) {
            text-align: center;
            margin-bottom: sp(6);
        }
    }

    &__tabs {
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            max-width: px-to-rem(624);
        }
    }

    .nav {
        --bs-nav-link-padding-x: 0;
        --bs-nav-underline-gap: 0;
        --bs-nav-underline-border-width: #{px-to-rem(1)};
        
        flex-wrap: nowrap;
        justify-content: stretch;

        &-item {
            flex-basis: 50%;
        }

        &-link {
            width: 100%;
            font-weight: $font-weight-bold;
            padding-top: 0;

            @include media-breakpoint-up(lg) {
                font-size: font-size('body-lg','desktop');
                padding-bottom: px-to-rem(18);
            }

            @include media-breakpoint-down (lg) {
                text-align: left;
                
                span {
                    display: block;
                }
            }
            
            &,
            &:hover,
            &:focus {
                color: $clr-darkergrey;
                border-bottom-color: $clr-darkergrey;
            }

            &.active {
                color: $clr-orange;
                border-bottom-color: $clr-orange;
            }
        }
    }

    .tab-content {
        @include media-breakpoint-up(lg) {
            padding-top: sp(5);
        }
    }

    .link-forgot-password {
        font-size: font-size('body-sm','mobile');
        font-weight: $font-weight-normal;
        color: $clr-dark;
    }

    &.forgot-password,
    &.new-password {
        @include media-breakpoint-down(lg) {
            padding-bottom: px-to-rem(82);
        }

        .section__inner {
            position: relative;
        }

        .section__title {
            margin-bottom: sp(5);
        }

        .request-password-body {
            p:first-of-type {
                text-align: center;
                margin-bottom: sp(5);
                color: $clr-darkergrey;
                font-weight: $font-weight-semibold;

                @include media-breakpoint-down(lg) {
                    font-size: font-size('body-sm','mobile');
                    text-align: left;
                }
            }

            .form-group {
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
            }
        }

        .btn-back {
            margin-bottom: sp(4);

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: px-to-rem(36);
            }
        }
    }

    &.forgot-password {
        .send-email-btn {
            text-align: center;

            .btn {
                justify-content: center;

                &.btn-submit {
                    float: left;
                }

                @include media-breakpoint-up(lg) {
                    &:not(.btn-submit) {
                        margin-top: sp(5);
                    }
                }
            }
        }
    }

    &.new-password {
        .new-password-msg {
            font-size: font-size('body-sm', 'mobile');
            font-weight: $font-weight-semibold;
            line-height: px-to-rem(17);
            text-align: center;

            @include media-breakpoint-up(lg) {
                margin-bottom: sp(5);
            }
    
            span.email {
                color: $clr-orange;
            }
        }

        .form-group:last-of-type {
            margin-bottom: 0;
        }
    }
}