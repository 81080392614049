// Footer

.footer {
    font-size: px-to-rem(14);
    font-weight: $font-weight-normal;

    a {
        text-decoration: none;
    }

    &__row {
        @include make-flex(row, center, space-between);

        padding: px-to-rem(30) 0;
        border-top: px-to-rem(1) solid $clr-menugrey;
        border-bottom: px-to-rem(1) solid $clr-menugrey;

        @include media-breakpoint-down(lg) {
            display: block;
            padding: px-to-rem(32) 0;
        }
    }

    &__management-section-wrap {
        @include make-flex(row, flex-start, space-between);

        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    &__logo-image {
        padding-right: px-to-rem(28);
    }

    &__reinsurance {
        @include make-flex(row, center, space-between);

        padding: px-to-rem(11) px-to-rem(16);
        align-items: center;
        gap: px-to-rem(8);
        border-radius: px-to-rem(10);
        font-weight: $font-weight-normal;
        font-size: px-to-rem(12);
        background-color: $clr-menugrey;

        &-wrapper {
            @include make-flex(row, center, flex-start);

            gap: px-to-rem(5);

            @include media-breakpoint-down(lg) {
                gap: px-to-rem(8);
            }
        }

        @include media-breakpoint-down(lg) {
            gap: px-to-rem(12);
        }
    }

    .management-section {
        @include make-flex(row, center, flex-start);

        flex-wrap: wrap;
        padding-top: px-to-rem(12);

        @include media-breakpoint-down(lg) {
            @include make-flex(column, flex-start, flex-start);

            gap: px-to-rem(8);
            padding: px-to-rem(20) 0 px-to-rem(18);
        }

        &__item {
            padding-right: px-to-rem(20);
        }
    }

    .terms-conditions {
        @include make-flex(row, center, flex-start);

        flex-wrap: wrap;
        padding: px-to-rem(30) 0;
        gap: px-to-rem(15);

        @include media-breakpoint-down(lg) {
            @include make-flex(column, flex-start, flex-start);

            gap: px-to-rem(8);
            padding: px-to-rem(26) 0 px-to-rem(30);
        }


        &__link {
            &:not(:hover) {
                color: $clr-darkgrey;
            }
        }
    }
}
