$arrow-height: px-to-rem(28);

.hf-tooltip {
    position: relative;
    cursor: pointer;
    display: inline-block;
    border: none;
    padding: 0;
    background: inherit;
    color: $clr-darkergrey;

    &,
    &:active,
    &:hover {
        text-decoration: none;
    }

    .icon {
        width: px-to-rem(24);
        height: px-to-rem(24);
    }

    &-text {
        position: absolute;
        bottom: 100%;
        padding: px-to-rem(11) sp(4);
        border-radius: px-to-rem(6);
        box-shadow: $box-shadow;
        background-color: $clr-white;
        font-size: px-to-rem(10);
        line-height: px-to-rem(13);
        color: $clr-darkgrey;
        min-width: px-to-rem(292);
        max-width: px-to-rem(292);
        transform: translate(px-to-rem(-24), calc(-1 * $arrow-height / 2));
        left: 50%;
        margin-left: 0;
        animation: fade-in 0.3s linear forwards;
        z-index: $z-index-popover-mobile;

        @keyframes fade-in {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }

        &::before {
            content: " ";
            position: absolute;
            display: block;
            height: $arrow-height; /* 20/16 */
            left: 0;
            bottom: -$arrow-height;
            width: 100%;
        }

        &::after {
            border-left: solid transparent px-to-rem(10); /* 10/16 */
            border-right: solid transparent px-to-rem(10); /* 10/16 */
            border-top: solid $clr-white px-to-rem(14);;
            bottom: calc(-1 * $arrow-height / 2);
            content: " ";
            height: 0;
            left: px-to-rem(15);
            margin-left: px-to-rem(-1);
            position: absolute;
            width: 0;
        }
    }
}
