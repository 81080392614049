.price {
    &-content {
        .content-wrapper {
            @include make-flex(row, flex-start, space-between);

            margin-top: px-to-rem(32);
            position: relative;
            gap: px-to-rem(20);
    
            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
                gap: px-to-rem(52);
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: px-to-rem(52);
            }
        }

        .column-left {
            width: calc(100% - #{px-to-rem(410)});
    
            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            @include media-breakpoint-up(lg) {
                padding-top: px-to-rem(143);
            }
        }

        .column-right {
            @include media-breakpoint-down(lg) {
                margin-bottom: sp(6);
            }
        }

        h1 {
            margin-bottom: sp(4);
            color: $clr-darkergrey;
        }

        h2 {
            font-size: font-size('h2','mobile');
            margin-bottom: sp(4);
            font-weight: $font-weight-normal;
            line-height: px-to-rem(36);

            span {
                font-weight: $font-weight-bold;
            }

            @include media-breakpoint-up(lg) {
                font-size: font-size('h2','desktop');
                line-height: px-to-rem(56);
            }
        }

        hr {
            margin: sp(5) 0;

            @include media-breakpoint-up(lg) {
                margin: sp(6) 0;
            }
        }
    }

    &-regions {
        margin-top: px-to-rem(17);

        &__title {
            color: $clr-orange;
        }

        &__list {
            font-size: font-size('body-lg', 'mobile');
            font-weight: $font-weight-normal;
            line-height: px-to-rem(22);

            @include media-breakpoint-up(lg) {
                column-count: 2;
                font-size: font-size('body-lg', 'desktop');
            }

            li {
                display: block;
                margin-bottom: px-to-rem(15);

                a {
                    text-decoration: none;
                    position: relative;
                    display: flex;
                    padding-left: sp(5);

                    &::before {
                        content: '';
                        position: absolute;
                        width: px-to-rem(21);
                        height: px-to-rem(21);
                        border-radius: $border-radius-pill;
                        border: px-to-rem(1) solid $clr-darkergrey;
                        left: 0;
                        top: 0;
                    }
    
                    &#{$const-active}::after {
                        content: '';
                        position: absolute;
                        width: px-to-rem(13);
                        height: px-to-rem(13);
                        border-radius: $border-radius-pill;
                        background-color: $clr-orange;
                        left: px-to-rem(4);
                        top: px-to-rem(4);
                    }
                }
            }
        }

        &__back-link {
            @include make-flex(row, center, flex-start);
    
            gap: px-to-rem(5);
            margin: px-to-rem(35) 0 px-to-rem(64);
            font-size: px-to-rem(16);
            line-height: px-to-rem(20);
            font-weight: $font-weight-semibold;
            color: $clr-dark;
            text-decoration: none;
    
            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(16);
                font-size: px-to-rem(12);
                line-height: px-to-rem(15);
            }
    
            &:hover {
                text-decoration: none;
            }
        }
    }

    &-chart {
        @include media-breakpoint-down(lg) {
            display: none;
        }

        &__title {
            color: $clr-cyan;

            @include media-breakpoint-up(lg) {
                padding-right: px-to-rem(150);
            }
        }
    }

    &-news {
        background-color: $clr-menugrey;

        @include media-breakpoint-up(lg) {
            padding: px-to-rem(31) 0 px-to-rem(23);
        }

        @include media-breakpoint-down(lg) {
            padding: sp(6) 0;

            .news-banner__title {
                margin-top: 0;
            }

            .news-banner__link {
                margin-bottom: 0;
            }
        }
    }
}