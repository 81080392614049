:root {
    border: 0;
}

.page {
    position: relative;
}

a,
.btn {
    &:focus-visible {
        --bs-btn-focus-box-shadow: #{$input-btn-focus-box-shadow};

        box-shadow: var(--bs-btn-focus-box-shadow);
        outline: 0;
    }
}

.list-unstyled {
    @include list-unstyled;
}

.no-gutters {
    @extend .g-0; // stylelint-disable-line
}

.row {
    --bs-gutter-x: 1rem; // 8px on mobile

    @include media-breakpoint-up(lg) {
        --bs-gutter-x: 1.25rem; // 10px on desktop
    }
}

.container-max {
    @include make-container;

    max-width: px-to-rem(1432);
}

.container,
.container-fluid,
.container-max {
    --bs-gutter-x: 2rem; // 16px padding-x on mobile (gutter * 0.5)

    @include media-breakpoint-up(lg) {
        --bs-gutter-x: 2.5rem; // 20px padding-x on desktop (gutter * 0.5)
    }
}