.promotional-banner {
    margin-top: px-to-rem(64);
    border: px-to-rem(2) solid $clr-orange;
    border-radius: px-to-rem(20);

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    .content {
        @include make-flex(row, center, flex-start);
        
        padding: px-to-rem(20);
        gap: px-to-rem(20);

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            gap: px-to-rem(16);
            background: none;
        }

        &-text {
            p, h1, h2, h3, h4 {
                margin: 0;
                font-weight: $font-weight-semibold;
            }

            p {
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(16);
                    line-height: px-to-rem(20);
                }
            }

            h4 {
                font-size: px-to-rem(26);
                line-height: px-to-rem(32);

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(20);
                    line-height: px-to-rem(24);
                }
            }
        }

        &__image.image {
            @include media-breakpoint-down(lg) {
                height: auto;
                width: 100%;
            }
        }

        &__title {
            padding-bottom: px-to-rem(7);

            @include media-breakpoint-down(lg) {
                padding-bottom: px-to-rem(16);
            }
        }
    }
}

.advices-banner {
    @include make-flex(row, flex-start, flex-start);

    gap: px-to-rem(20);
    margin-top: px-to-rem(64);

    @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 0;
    }

    .image {
        width: 50%;
        border-radius: px-to-rem(20);

        @include media-breakpoint-down(lg) {
            width: 100%;
            border-radius: px-to-rem(10);
        }
    }

    .content {
        margin-top: px-to-rem(32);

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(16);
        }

        &__note {
            font-size: px-to-rem(14);
            line-height: px-to-rem(17);
            font-weight: $font-weight-normal;

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(12);
                line-height: px-to-rem(20);
            }
        }

        &__title {
            margin: px-to-rem(8) 0 px-to-rem(16);
            font-size: px-to-rem(46);
            line-height: px-to-rem(56);

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(30);
                line-height: px-to-rem(36);
            }
        }

        &__links {
            line-height: px-to-rem(26);

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(14);
                line-height: px-to-rem(17);
            }

            p {
                margin-bottom: px-to-rem(8);

                @include media-breakpoint-down(lg) {
                    margin-bottom: px-to-rem(16);
                }
            }
        }

        &__button {
            width: fit-content;
            margin-top: px-to-rem(32);
            line-height: px-to-rem(20);
            font-weight: $font-weight-semibold;

            a {
                padding: px-to-rem(15) px-to-rem(40) px-to-rem(17);
                border-radius: px-to-rem(10);
                text-decoration: none;

                @include media-breakpoint-down(lg) {
                    padding: px-to-rem(11) px-to-rem(32) px-to-rem(13);
                }
            }
        }
    }
}

.questions-banner {
    margin-top: px-to-rem(64);
    padding: px-to-rem(64) 0;
    background-color: $clr-menugrey;

    @include media-breakpoint-down(lg) {
        padding: px-to-rem(32) 0;
    }

    &-item {
        @include make-flex(row, flex-start, space-between);

        gap: px-to-rem(9);
        height: 100%;
        padding: px-to-rem(30) px-to-rem(23) px-to-rem(28) px-to-rem(30);
        background-color: $white;
        border-radius: px-to-rem(20);

        @include media-breakpoint-down(lg) {
            @include make-flex(column-reverse, center, flex-end);

            gap: px-to-rem(16);
            padding: px-to-rem(15);
        }

        .content {
            &__title {
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);
    
                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(16);
                    line-height: px-to-rem(20);
                }

                p {
                    margin-bottom: px-to-rem(4);

                    @include media-breakpoint-down(lg) {
                        margin-bottom: px-to-rem(8);
                    }
                }
            }
    
            &__subtitle {
                font-size: px-to-rem(26);
                line-height: px-to-rem(32);
                font-weight: $font-weight-semibold;
    
                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(20);
                    line-height: px-to-rem(24);
                }
            }

            &__button {
                line-height: px-to-rem(20);
                font-weight: $font-weight-semibold;
                
                a {
                    display: inline-block;
                    width: 100%;
                    max-width: px-to-rem(230);
                    padding: px-to-rem(15) 0 px-to-rem(17);
                    border-radius: px-to-rem(10);
                    text-decoration: none;
                    text-align: center;
    
                    @include media-breakpoint-down(lg) {
                        padding: px-to-rem(11) 0 px-to-rem(13);
                        font-size: px-to-rem(14);
                    }
                }

                p {
                    margin: 0;
                }
            }
        }

        .image {
            height: px-to-rem(132);

            @include media-breakpoint-down(lg) {
                height: px-to-rem(75);
            }
        }
    }

    .experience-components {
        @include make-flex(row, stretch, space-between);

        gap: px-to-rem(20);

        @include media-breakpoint-down(lg) {
            gap: px-to-rem(14);
        }

        .experience-component {
            width: 50%;
        }
    }
}

.best-rates-block {
    width: px-to-rem(371);
    padding: px-to-rem(33) px-to-rem(30) px-to-rem(23);
    border: px-to-rem(2) solid $clr-orange;
    border-radius: px-to-rem(20);
    box-shadow: $box-shadow-lg;
    color: $clr-darkergrey;
    background-color: $white;
    position: absolute;
    top: px-to-rem(-364);
    right: px-to-rem(20);
    z-index: 1;

    @include media-breakpoint-down(lg) {
        position: static;
        width: 100%;
        padding: px-to-rem(23) px-to-rem(20) px-to-rem(20);
    }

    @include media-breakpoint-down(xl) {
        .price-content & {
            position: static;
            width: 100%;
            padding: px-to-rem(23) px-to-rem(20) px-to-rem(20);
        }
    }

    .content {
        &__logo {
            height: px-to-rem(38);
        }

        &__description {
            padding: px-to-rem(11) 0 px-to-rem(25);
            font-size: px-to-rem(24);
            line-height: px-to-rem(30);
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(lg) {
                padding-bottom: px-to-rem(20);
            }
        }

        .postal-code {
            &-form {
                @include make-flex(row, stretch, space-between);

                position: relative;

                .field-container {
                    @include make-flex(row, stretch, space-between);

                    width: 100%;
                    margin: 0;
                    position: static;

                    .error-message {
                        margin-top: px-to-rem(12);
                        position: static;
                        order: 3;
                    }
                }

                .result-container {
                    top: px-to-rem(56);
                }
            }

            &-input {
                order: 1;
                width: px-to-rem(164);
                height: px-to-rem(36);
                min-height: auto;
                border-radius: px-to-rem(10);
                border: px-to-rem(1) solid $clr-darkergrey;
                font-weight: $font-weight-medium;
                text-align: center;
                font-size: px-to-rem(14);
                outline: none;

                @include media-breakpoint-down(lg) {
                    width: calc(54% - #{px-to-rem(10)});
                }

                &::placeholder {
                    color: $clr-darkergrey;
                }
            }

            &-submit {
                height: px-to-rem(36);
                order: 2;
                width: px-to-rem(127);
                font-weight: $font-weight-semibold;
                border-radius: px-to-rem(10);
                background-color: $clr-darkergrey;
                border: none;
                color: $white;

                @include media-breakpoint-down(lg) {
                    width: calc(46% - #{px-to-rem(10)});
                }
            }
        }

        .rating-block {
            margin-top: px-to-rem(32);
            padding-top: px-to-rem(21);
            border-top: px-to-rem(1) solid $clr-darkergrey;

            &__title {
                @include text-size(18, 22, 700);

                margin-bottom: px-to-rem(5);
            }

            &__rate {
                @include text-size(75, 67, 700);

                margin-bottom: px-to-rem(11);
                color: $clr-orange;
            }

            &__count {
                @include text-size(14, 17, 400);

                margin: px-to-rem(5) 0 px-to-rem(4);

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.price-intro-banner {
    position: relative;
    margin-top: sp(6);
    background-color: $clr-orange;
    border-radius: $border-radius-lg;
    z-index: 1;

    @include media-breakpoint-down(lg) {
        margin-top: 0;
    }

    &-container {
        padding: 0 px-to-rem(20);

        @include media-breakpoint-down(lg) {
            padding: 0 px-to-rem(16);
        }
    }


    .content {
        // @include make-flex(column, flex-start, space-between);

        // position: absolute;
        padding: px-to-rem(17) px-to-rem(16);
        color: $clr-darkergrey;

        @include media-breakpoint-up(lg) {
            height: px-to-rem(538);
            padding: px-to-rem(45) px-to-rem(50) px-to-rem(55);
        }

        @include media-breakpoint-down(lg) {
            text-align: center;
        }

        &-body {
            @include media-breakpoint-up(lg) {
                @include make-flex(row, null, null);

                gap: px-to-rem(30);
            }

            @include media-breakpoint-up(xxl) {
                padding-left: px-to-rem(40);
            }
        }

        &-region {
            color: $clr-white;
            font-weight: $font-weight-semibold;
            line-height: px-to-rem(20);
            margin-bottom: px-to-rem(10);

            &::after {
                content: "";
                display: block;
                width: px-to-rem(31);
                height: 0;
                border-top: solid px-to-rem(1) $clr-white;
                margin: px-to-rem(10) 0 0;

                @include media-breakpoint-down(lg) {
                    margin: px-to-rem(10) auto 0;
                }
            }
        }

        &-date {
            font-size: px-to-rem(14);
            line-height: px-to-rem(22);
            font-weight: $font-weight-normal;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &-prices {
            @include make-flex(column, null, null);
        }

        &-price {
            margin-top: px-to-rem(17);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(10);
            }

            &__average {
                color: $white;
                margin-bottom: px-to-rem(20);
            }

            &__reference {
                margin-bottom: px-to-rem(20);
            }

            .prompt {
                display: none;
            }

            .label {
                position: relative;
                font-size: px-to-rem(16);
                line-height: px-to-rem(20);
                font-weight: $font-weight-semibold;
                margin-bottom: px-to-rem(2);

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(14);
                    line-height: px-to-rem(17);
                    font-weight: $font-weight-medium;
                }

                .prompt {
                    bottom: px-to-rem(25);

                    @include media-breakpoint-down(lg) {
                        bottom: px-to-rem(20);
                    }
                }
            }

            .value {
                font-size: px-to-rem(28);
                line-height: px-to-rem(34);
                font-weight: $font-weight-bold;

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(22);
                    line-height: px-to-rem(27);
                }

                span:last-of-type {
                    font-size: px-to-rem(18);
                    line-height: px-to-rem(22);

                    @include media-breakpoint-down(lg) {
                        font-size: px-to-rem(14);
                        line-height: px-to-rem(17);
                    }
                }
            }

            &__trend {
                @include make-flex(row, center, null);

                font-size: font-sieze(body-sm, mobile);
                line-height: px-to-rem(22);
                font-weight: $font-weight-bold;

                &[data-trend=""] {
                    display: none;
                }

                &[data-trend="down"] {
                    .trend-icon {
                        transform: rotate(90deg);
                        margin-left: px-to-rem(2);
                    }
                }

                @include media-breakpoint-down(lg) {
                    justify-content: center;
                }
            }
        }

        &-title {
            color: $white;
            font-size: font-size(h1, desktop);
            line-height: px-to-rem(67);
            font-weight: $font-weight-semibold;
            margin-bottom: px-to-rem(40);
            max-width: px-to-rem(550);

            @include media-breakpoint-down(lg) {
                font-size: font-size(h2, mobile);
                line-height: px-to-rem(37);
                font-weight: $font-weight-normal;
                margin-bottom: px-to-rem(20);
                max-width: 100%;
            }
        }
    }

    &__back-link {
        font-size: font-size(body-xs, mobile);
        font-weight: $font-weight-normal;
        line-height: px-to-rem(22);
        color: $clr-dark;

        @include media-breakpoint-up(lg) {
            @include make-flex(row, center, null);

            flex-wrap: wrap;
            gap: px-to-rem(5);
            margin: auto 0 px-to-rem(74);
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }

        .icon {
            display: inline-flex;
            font-size: font-size(body-sm, desktop);
            width: px-to-rem(21);
            height: px-to-rem(21);
            vertical-align: middle;
            align-items: center;
            justify-content: center;
        }

        a {
            text-decoration: none;
        }
    }

    .map-container {
        svg.map {
            width: 100%;
            height: auto;
            max-height: 100%;

            &:not(.departement) path {
                transition: fill .3s ease;
                cursor: pointer;
            }            
        }

        @include media-breakpoint-up(lg) {
            width: px-to-rem(410);
            height: px-to-rem(435);
        }

        @include media-breakpoint-down(lg) {
            margin-bottom: px-to-rem(18);
        }
    }
}

.news-banner {
    &__title {
        margin: px-to-rem(52) 0 px-to-rem(32);
        font-size: px-to-rem(46);
        line-height: px-to-rem(56);
        color: $clr-darkgrey;

        @include media-breakpoint-down(lg) {
            margin: px-to-rem(64) 0 px-to-rem(16);
            font-size: px-to-rem(30);
            line-height: px-to-rem(36);
        }
    }

    &__link {
        @include make-flex(row, center, flex-start);

        gap: px-to-rem(5);
        margin: px-to-rem(35) 0 px-to-rem(64);
        font-size: px-to-rem(16);
        line-height: px-to-rem(20);
        font-weight: $font-weight-semibold;
        color: $clr-dark;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(16);
            font-size: px-to-rem(12);
            line-height: px-to-rem(15);
        }

        &:hover {
            text-decoration: none;
        }
    }

    .list-container {
        @include make-flex(row, stretch, flex-start);

        gap: px-to-rem(21);

        @include media-breakpoint-down(lg) {
            width: calc(100% + #{px-to-rem(16)});
            padding-right: px-to-rem(16);
            overflow-x: scroll;
        }

        @include media-breakpoint-up(lg) {
            flex-wrap: wrap;
        }

        .article {
            width: calc(50% - #{px-to-rem(11)});
            border: px-to-rem(1) solid $clr-grey;
            border-radius: px-to-rem(20);
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                min-width: px-to-rem(306);
            }

            @include media-breakpoint-up(lg) {
                width: calc((100% / 3) - #{px-to-rem(14)});
            }

            &__image {
                width: 100%;
                margin: 0;
                border-radius: inset(20, 20, 0, 0);
            }

            &__description {
                padding: px-to-rem(18) px-to-rem(20) px-to-rem(40);
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);
                font-weight: $font-weight-semibold;
                color: $clr-darkgrey;
                word-wrap: break-word;

                @include media-breakpoint-down(lg) {
                    padding-bottom: px-to-rem(30);
                    font-size: px-to-rem(16);
                    line-height: px-to-rem(20);
                }
            }

            &__date {
                display: inline-block;
                width: 100%;
                margin: 0;
                padding-bottom: px-to-rem(10);
                font-size: px-to-rem(14);
                line-height: px-to-rem(17);
                font-weight: $font-weight-normal;
                color: $clr-dark;

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(12);
                    line-height: px-to-rem(15);
                    color: $clr-darkergrey;
                }
            }
        }
    }
}

.reinsurance {
    &__title {
        @include text-size(26, 32, 400);

        margin-bottom: px-to-rem(32);
        text-align: center;
        color: $clr-darkshade;

        span {
            font-weight: $font-weight-semibold;
        }

        @include media-breakpoint-down(lg) {
            @include text-size(22, 27, 400);

            margin-bottom: px-to-rem(18);

            span {
                @include text-size(20, 24, 600);

                display: inline-block;
                width: 100%;
            }
        }
    }

    &__content {
        @include make-flex(row, stretch, space-between);

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    &-container {
        .items {
            @include make-flex(row, center, flex-start);

            @include media-breakpoint-down(lg) {
                align-items: flex-start;
            }

            .item {
                @include make-flex(row, center, flex-start);

                height: px-to-rem(120);
                border-left: px-to-rem(1) solid $clr-grey;
                padding-left: px-to-rem(25);

                @include media-breakpoint-down(lg) {
                    width: 33%;
                    height: auto;
                    border: none;
                    padding: 0;
                    flex-direction: column;
                }

                &__order {
                    @include text-size(75, 92, 250);
    
                    color: $clr-orange;

                    @include media-breakpoint-down(lg) {
                        @include text-size(42, 51, 250);
                    }
                }
    
                &__text {
                    @include text-size(14, 17, 400);
    
                    max-width: px-to-rem(195);
                    padding: inset(0, 23, 0, 15);
                    color: $clr-darkergrey;

                    @include media-breakpoint-down(lg) {
                        @include text-size(12, 20, 400);

                        text-align: center;
                    }
                    
                    p {
                        margin: 0;
                    }

                    strong {
                        font-weight: $font-weight-bold;
                    }
                }

                &:first-of-type {
                    border: none;
                    padding: 0;
                }
            }
        }

        .rating-block {
            @include make-flex(row, center, flex-start);

            gap: px-to-rem(15);
            padding: px-to-rem(27);
            border: px-to-rem(2) solid $clr-orange;
            border-radius: px-to-rem(20);
            color: $clr-darkergrey;

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(32);
                padding: px-to-rem(16);
            }

            &__info {
                @include media-breakpoint-down(lg) {
                    @include make-flex(row, flex-start, space-between);

                    gap: px-to-rem(20);
                }
            }

            &__rate {
                @include text-size(75, 67, 700);

                color: $clr-orange;

                @include media-breakpoint-down(lg) {
                    @include text-size(34, 41, 700);

                    text-align: right;
                }
            }

            &__title {
                @include text-size(18, 22, 700);

                @include media-breakpoint-down(lg) {
                    @include text-size(16, 20, 700);
                }
            }

            &__count {
                @include text-size(14, 17, 400);

                @include media-breakpoint-down(lg) {
                    @include text-size(12, 20, 400);
                }
            }

            .stars-container {
                @include make-flex(row, flex-start, flex-start);

                gap: px-to-rem(3);
                padding: px-to-rem(4) 0;

                @include media-breakpoint-down(lg) {
                    padding: px-to-rem(3) 0 px-to-rem(2);
                    justify-content: flex-end;
                }

                svg {
                    width: px-to-rem(15.4);
                    height: px-to-rem(13.8);
                }
            }
        }
    }
}

.price-chart-banner {
    margin-top: px-to-rem(64);
    background-color: $clr-menugrey;
    border-radius: px-to-rem(20);

    .content {
        @include make-flex(row, flex-start, space-between);

        padding: px-to-rem(64) 0;

        @include media-breakpoint-down(lg) {
            padding: px-to-rem(32) 0;
        }

        &-date {
            @include text-size(14, 17, 400);

            color: $clr-dark;

            @include media-breakpoint-down(lg) {
                @include text-size(12, 20, 400);
            }
        }

        &-title {
            @include text-size(46, 56, 400);

            padding: px-to-rem(8) 0;
            color: $clr-darkergrey;

            @include media-breakpoint-down(lg) {
                @include text-size(30, 36, 400);
            }
        }

        &-prices {
            @include make-flex(row, flex-start, flex-start);

            gap: px-to-rem(30);

            .price {
                &-title {
                    @include text-size(16, 26, 700);

                    position: relative;
                    color: $clr-dark;

                    @include media-breakpoint-down(lg) {
                        @include text-size(14, 17, 500);
                    }

                    .icon {
                        font-size: px-to-rem(14);
            
                        &:hover {
                            cursor: pointer;
            
                            ~ .prompt {
                                position: absolute;
                                display: block;
                                width: 100%;
                                bottom: px-to-rem(40);
                                left: 0;
                                padding: px-to-rem(10);
                                border-radius: px-to-rem(10);
                                color: $clr-darkgrey;
                                font-size: px-to-rem(10);
                                font-weight: $font-weight-normal;
                                box-shadow: $box-shadow;
                                background-color: $white;
                                text-align: justify;
                                line-height: px-to-rem(20);
                                z-index: 10;
                            }
                        }
                    }
                }

                &-value {
                    @include text-size(28, 34, 700);

                    @include media-breakpoint-down(lg) {
                        @include text-size(22, 27, 700);
                    }

                    span {
                        @include text-size(18, 22, 700);

                        @include media-breakpoint-down(lg) {
                            @include text-size(14, 17, 700);
                        }
                    }
                }
            }

            .column {
                &-left {
                    .price-value {
                        color: $clr-orange;
                    }
                }

                &-right {
                    .price-value {
                        color: $clr-cyan;
                    }
                }
            }
        }

        &-link {
            @include text-size(16, 20, 600);
            @include make-flex(row, center, flex-start);

            gap: px-to-rem(8);
            margin-top: px-to-rem(33);
            color: $clr-dark;
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(16);
            }
        }

        &-chart {
            .price-chart {
                &__title {
                    display: none;
                }
        
                .chart-legend {
                    display: none;
                }
            }

            .chart-container {
                margin-bottom: 0;

                + * {
                    display: none;
                } 
            }

            .canvas-container {
                height: px-to-rem(300);
            }
        }
    }
}

.experience-assets-priceChartBanner {
    + .container {
        .newsletter {
            margin-top: px-to-rem(-52);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(-68);
            }
        }
    }
}
