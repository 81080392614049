.section-contact {
    @include media-breakpoint-up(lg) {
        padding-top: sp(5);
    }

    .section__sidebar {
        @include media-breakpoint-up(lg) {
            @include make-flex(column, flex-end, null);
        }
    }

    .section__main-inner {
        @include media-breakpoint-up(lg) {
            padding: px-to-rem(32) 0;
        }
    }

    .section__sidebar-inner {
        @include media-breakpoint-up(lg) {
            max-width: px-to-rem(411);
        }

        @include media-breakpoint-down(lg) {
            padding-top: px-to-rem(33);
            padding-bottom: px-to-rem(35);
        }
    }

    form.contact-us {
        @include media-breakpoint-up(lg) {
            .form-group:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}