.chart-container {
    width: 100%;
    position: relative;
    margin: px-to-rem(22) 0 px-to-rem(34);

    .canvas-container img {
        display: none
    }

    .chart-price {
        position: relative;
    }

    .chart-legend {
        margin-top: sp(4);

        ul {
            @include make-flex(row, null, center);

            gap: px-to-rem(60);
        }

        li {
            @include make-flex(row, center, null);

            position: relative;
            font-size: font-size('body-xs', 'mobile');

            &:first-child a {
                display: none;
            }

            &:last-child {
                order: -1;
            }

            > span {
                display: inline-block;
                flex-shrink: 0;
                width: px-to-rem(45);
                height: px-to-rem(10);
                margin-right: px-to-rem(10);
            }

            p {
                margin: 0;

                a {
                    position: relative;
                    display: inline-block;
                    padding: px-to-rem(2);
                    vertical-align: middle;
                    margin-left: sp(3);

                    .icon {
                        color: $clr-cyan;
                    }

                    span + span {
                        display: none;
                        position: absolute;
                        top: px-to-rem(30);
                        left: px-to-rem(-152);
                        border: px-to-rem(1) solid $clr-cyan;
                        border-radius: $border-radius-sm;
                        background-color: $clr-white;
                        width: px-to-rem(320);
                        padding: sp(4);
                        box-shadow: $box-shadow;
                        z-index: 1;

                        &::before {
                            content: " ";
                            position: absolute;
                            bottom: calc(100% + 0.5px);  /* At the top of the tooltip */
                            left: 50%;
                            margin-left: px-to-rem(-8);
                            border-width: px-to-rem(8);
                            border-style: solid;
                            border-color: transparent transparent $clr-cyan;
                        }

                        &::after {
                            content: " ";
                            position: absolute;
                            bottom: calc(100% - 1px);  /* At the top of the tooltip */
                            left: 50%;
                            margin-left: px-to-rem(-8);
                            border-width: px-to-rem(8);
                            border-style: solid;
                            border-color: transparent transparent $clr-white;
                          }
                    }

                    &:hover {
                        span + span {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

#chart-tooltip {
    opacity: 1;
    pointer-events: none;
    background-color: $clr-white;
    border: px-to-rem(1) solid $clr-orange;
    border-radius: $border-radius-sm;
    position: absolute;
    transform: translate(-50%, 0);
    transition: all .1s ease;
    padding: 0 !important;
    font-size: font-size('body-sm', 'mobile');
    z-index: $z-index-popover;

    &::after {
        content: '';
        position: absolute;
        bottom: px-to-rem(-7);
        left: 50%;
        margin-left: px-to-rem(-6);
        width: px-to-rem(12);
        height: px-to-rem(12);
        transform: rotate(-135deg);
        background-color: $clr-white;
        border-top: px-to-rem(1) solid $clr-orange;
        border-left: px-to-rem(1) solid $clr-orange;
    }

    .tooltip-content {
        overflow: hidden;
        border-radius: $border-radius-sm;
        width: px-to-rem(190);
    }

    .tooltip-title {
        background-color: $clr-orange;
        color: $clr-white;
        font-size: px-to-rem(10);
        text-align: center;

        +.tooltip-item {
            margin-top: px-to-rem(7);
            font-weight: $font-weight-medium;

            .tooltip-label {
                font-size: font-size('body-xs', 'mobile');
            }

            .tooltip-value {
                order: -1;
                font-size: font-size('h4', 'desktop');
                line-height: px-to-rem(26);
                color: $clr-orange;
            }
        }
    }

    .tooltip-item {
        @include make-flex(column, center, flex-start);

        + .tooltip-item {
            @include make-flex(row, center, center);

            gap: px-to-rem(5);
            font-size: px-to-rem(10);
            font-style: italic;
            color: $clr-darkergrey;

            .tooltip-value {
                font-weight: $font-weight-medium;
            }
        }
    }
}