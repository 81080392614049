.articles-list-page {
    position: relative;
    color: $clr-darkergrey;

    .title {
        @include text-size(36, 40, 600);

        margin: px-to-rem(64) 0 px-to-rem(32);
        color: $clr-orange;

        @include media-breakpoint-down(lg) {
            margin-top: sp(5);
        }

        &-news {
            margin: sp(5) 0;
            color: $clr-orange;

            @include media-breakpoint-up(lg) {
                margin: sp(6) 0;
            }
        }
    }

    .tips {
        &-main-categories {
            @include make-flex(row, flex-end, space-between);

            width: 100%;
            font-size: px-to-rem(18);
            line-height: px-to-rem(22);
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(lg) {
                font-size: px-to-rem(16);
                line-height: px-to-rem(20);
            }

            .link {
                display: inline-block;
                width: 50%;
                padding-bottom: px-to-rem(18);
                border-bottom: px-to-rem(1) solid $clr-darkergrey;
                text-align: center;
                color: $clr-darkergrey;
                text-decoration: none;

                @include media-breakpoint-down(lg) {
                    padding-left: px-to-rem(9);
                    text-align: left;

                    &:first-child {
                        padding-left: px-to-rem(3);
                    }
                }

                &.active {
                    pointer-events: none;
                    color: $clr-orange;
                    border-color: $clr-orange;
                }
            }
        }

        &-sub-categories {
            margin: px-to-rem(32) 0 px-to-rem(61);

            @include media-breakpoint-down(lg) {
                width: calc(100% + #{px-to-rem(16)});
                overflow-x: scroll;
                margin-bottom: px-to-rem(64);

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .content {
                @include make-flex(row, center, flex-start);

                gap: px-to-rem(20);

                @include media-breakpoint-down(lg) {
                    white-space: nowrap;
                }

                .link {
                    padding: px-to-rem(8) px-to-rem(20);
                    font-size: px-to-rem(12);
                    line-height: px-to-rem(14);
                    font-weight: $font-weight-normal;
                    border: px-to-rem(1) solid $clr-orange;
                    border-radius: px-to-rem(4);
                    color: $clr-orange;
                    text-decoration: none;

                    &.active {
                        pointer-events: none;
                        background-color: $clr-orange;
                        color: $white;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }
    }

    .list-container {
        @include make-flex(row, stretch, space-between);

        gap: px-to-rem(21);
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            margin-bottom: sp(6);
        }

        .article {
            width: calc(50% - #{px-to-rem(11)});
            border: px-to-rem(1) solid $clr-grey;
            border-radius: px-to-rem(20);
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            &__image {
                width: 100%;
                height: px-to-rem(180);
                object-fit: cover;
                border-radius: inset(20, 20, 0, 0);
            }

            &__description {
                padding: px-to-rem(18) px-to-rem(20) px-to-rem(40);
                font-size: px-to-rem(18);
                line-height: px-to-rem(22);
                font-weight: $font-weight-semibold;
                color: $clr-darkgrey;
                word-wrap: break-word;

                @include media-breakpoint-down(lg) {
                    padding-bottom: px-to-rem(30);
                    font-size: px-to-rem(16);
                    line-height: px-to-rem(20);
                }
            }

            &__date {
                display: inline-block;
                width: 100%;
                padding-bottom: px-to-rem(10);
                font-size: px-to-rem(14);
                line-height: px-to-rem(17);
                font-weight: $font-weight-normal;
                color: $clr-dark;

                @include media-breakpoint-down(lg) {
                    font-size: px-to-rem(12);
                    line-height: px-to-rem(15);
                    color: $clr-darkergrey;
                }
            }
        }
    }

    .best-rates-block {
        top: 0;

        @include media-breakpoint-down(lg) {
            margin: px-to-rem(64) 0;
        }
    }

    .column-left {
        width: calc(100% - #{px-to-rem(410)});

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    .pagination {
        @include make-flex(row, center, center);

        gap: px-to-rem(10);
        margin: px-to-rem(64) 0;

        &__link {
            @include make-flex(row, center, center);

            height: px-to-rem(30);
            width: px-to-rem(30);
            border-radius: px-to-rem(22);
            font-size: px-to-rem(12);
            line-height: px-to-rem(14);
            font-weight: $font-weight-normal;
            background-color: $clr-light;
            text-decoration: none;
            color: $black;

            &.active {
                color: $white;
                background-color: $clr-orange;
            }
        }

        &__dots {
            margin: 0 px-to-rem(4);
        }

        &__prev-arrow {
            transform: rotate(180deg);
            margin-right: px-to-rem(15);
        }

        &__next-arrow {
            margin-left: px-to-rem(15);
        }
    }
}

.article-page {
    @include make-flex(row, stretch, center);

    flex-wrap: wrap;

    .best-rates-wrapper {
        position: relative;

        @include media-breakpoint-down(lg) {
            margin: px-to-rem(64) 0;
            order: 3;
        }
    }

    .content-container {
        @include media-breakpoint-up(lg) {
            min-height: px-to-rem(530);
        }
    }

    .article {
        width: calc(100% - #{px-to-rem(411)});
        color: $clr-darkergrey;
        background-color: $white;
        word-wrap: break-word;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        &__date {
            @include text-size(14, 17, 400);

            margin: px-to-rem(26) 0 px-to-rem(32);

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &__title {
            @include text-size(36, 40, 600);

            margin: px-to-rem(20) 0 sp(4);
            color: $clr-orange;

            @include media-breakpoint-down(lg) {
                @include text-size(30, 36, 600);

                margin: sp(4) 0;
            }

            span {
                color: $clr-orange;
            }
        }

        &__image {
            img {
                width: 100%;
                max-width: 100%;
                min-height: px-to-rem(180);
                height: auto;
            }

            margin: sp(5) 0;
        }

        .content-asset {
            p {
                @include text-size(14, 22);

                text-align: justify;
                color: $black;
            }

            h2 {
                @include text-size(24, 28);

                margin-top: px-to-rem(32);
                color: $clr-orange;

                @include media-breakpoint-down(lg) {
                    &:first-child {
                        margin-top: px-to-rem(32);
                    }
                }

                ~div,
                ~p {
                    margin-top: px-to-rem(16);

                    @include media-breakpoint-down(lg) {
                        margin-top: px-to-rem(32);
                    }
                }
            }

            img {
                width: 100%;
                margin-top: px-to-rem(64);
            }
        }
    }

    .return-back {
        @include text-size(16, 20, 600);

        margin-top: sp(6);

        @include media-breakpoint-down(lg) {
            margin-top: sp(5);
        }

        &__link {
            @include make-flex(row, center, flex-start);

            gap: px-to-rem(5);
            color: $clr-dark;
            text-decoration: none;
        }
    }

    .best-rates-block {
        top: px-to-rem(32);
        right: px-to-rem(20);
    }

    .news-banner {
        margin-bottom: px-to-rem(87);

        @include media-breakpoint-down(lg) {
            margin-bottom: px-to-rem(64);
        }

        &-container {
            width: 100%;
            margin-top: px-to-rem(57);
            background-color: $clr-menugrey;
        }

        &__link {
            margin-top: px-to-rem(33);
        }

        &__title {
            margin-top: px-to-rem(83);

            @include media-breakpoint-down(lg) {
                margin-top: px-to-rem(64);
            }
        }
    }
}