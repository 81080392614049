.error-page {
    font-weight: $font-weight-semibold;

    .header {
        &__icon {
            height: px-to-rem(60);
            margin-top: px-to-rem(32);

            @include media-breakpoint-down(lg) {
                height: px-to-rem(40);
                margin-top: px-to-rem(10);
            }
        }
    }

    &__title {
        margin: px-to-rem(60) 0 px-to-rem(20);
        font-size: px-to-rem(66);
        line-height: px-to-rem(67);
        font-weight: $font-weight-semibold;
        color: $clr-darkergrey;

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(58);
            font-size: px-to-rem(36);
            line-height: px-to-rem(38);
        }

        &.orange {
            color: $clr-orange;
        }
    }

    &__description {
        margin: px-to-rem(20) 0;
        color: $clr-darkgrey;
        font-size: px-to-rem(26);
        line-height: px-to-rem(32);

        @include media-breakpoint-down(lg) {
            margin-bottom: px-to-rem(26);
            font-size: px-to-rem(20);
            line-height: px-to-rem(24);
        }
    }

    &__code {
        margin-bottom: px-to-rem(60);
        font-size: px-to-rem(18);
        line-height: px-to-rem(22);
        color: $clr-darkgrey;

        @include media-breakpoint-down(lg) {
            margin-bottom: px-to-rem(40);
        }
    }

    &__link {
        width: px-to-rem(270);
        padding: px-to-rem(15) px-to-rem(20) px-to-rem(17);
        justify-content: center;
        background-color: $clr-darkergrey;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    &.maintenance-page {
        margin-top: px-to-rem(150);

        @include media-breakpoint-down(lg) {
            margin-top: px-to-rem(50);
        }

        .header {
            padding: 0;
        }

        .footer {
            background-color: #E7E7E7;
            margin-top: px-to-rem(50);
            height: calc(100vh - #{px-to-rem(470)});

            @include media-breakpoint-down(lg) {
                height: calc(100vh - #{px-to-rem(442)});
            }
        }

        .main-content {
            @include make-flex(row, flex-end, center);

            gap: px-to-rem(30);

            .error-page {
                &__title {
                    @include text-size(22, 24, 700);

                    margin: px-to-rem(30) 0;
                }

                &__description {
                    @include text-size(16, 18, 400);
                }
            }

            .maintenance__icon {
                height: px-to-rem(200);
            }
        }
    }
}