.field-container {
    @include make-flex(column-reverse, flex-start, flex-start);

    position: relative;
    flex-wrap: wrap;
    gap: px-to-rem(8);

    @include media-breakpoint-down(lg) {
        width: 100%;
        margin: 0 px-to-rem(20) 0 px-to-rem(17);
    }

    .error {
        color: $clr-pomegranate;
    }

    .error-message {
        @include make-flex(row, flex-start, flex-start);
    
        position: absolute;
        top: px-to-rem(65);
        width: 100%;
        padding: px-to-rem(10) px-to-rem(10) px-to-rem(10) px-to-rem(20);
        font-size: px-to-rem(14);
        border-radius: px-to-rem(10);
        background: $clr-pomegranate;
        color: $white;
        border: px-to-rem(1) solid $white;
        box-shadow: $box-shadow;
    
        @include media-breakpoint-down(lg) {
            position: static;
            width: 100%;
            line-height: normal;
        }
    }
}
