$custom-colors: (
    "orange": $clr-orange,
    "pomegranate": $clr-pomegranate,

    "black": $clr-black,
    "white": $clr-white,
    "cyan": $clr-cyan,
    "lightergrey": $clr-lightergrey,
    "lightgrey": $clr-grey,
    "grey": $clr-grey,
    "darkgrey": $clr-darkgrey,
    "darkergrey": $clr-darkergrey,
    "green": $clr-green,
    "yellow": $clr-yellow,

    "overlay": $clr-overlay,
    "success": $clr-green,
    // "warning":          $clr-warning,
    "danger": $clr-pomegranate
);
$theme-colors: map.merge($theme-colors, $custom-colors);
$spacer: 1rem !default;
$custom-spacers: (
    0: 0,
    4: $spacer * .25,
    6: $spacer * .38,
    8: $spacer * .5,
    10: $spacer * .63,
    12: $spacer * .75,
    16: $spacer,
    20: $spacer * 1.25,
    24: $spacer * 1.5,
    32: $spacer * 2,
    36: $spacer * 2.25,
    40: $spacer * 2.5,
    48: $spacer * 3,
    56: $spacer * 3.5,
    64: $spacer * 4,
    72: $spacer * 4.5,
    80: $spacer * 5,
    100: $spacer * 5.25,
    148: $spacer * 9.25,
    ) !default;
$negative-custom-spacers: (
    0: 0,
    4: $spacer * -.25,
    6: $spacer * -.38,
    8: $spacer * -.5,
    10: $spacer * -.63,
    12: $spacer * -.75,
    16: $spacer * -1,
    20: $spacer * -1.25,
    24: $spacer * -1.5,
    32: $spacer * -2,
    36: $spacer * -2.25,
    40: $spacer * -2.5,
    48: $spacer * -3,
    56: $spacer * -3.5,
    72: $spacer * -4.5,
    80: $spacer * -5,
    100: $spacer * -5.25,
    148: $spacer * -9.25,
    ) !default;
$enable-negative-margins: true;

// Typography

$enable-rfs: false !default;
$font-family-base: font-family(primary);
$headings-font-family: font-family(primary);

// Settings for anchors.

$link-color: $clr-darkergrey;
$link-hover-color: $clr-orange;
$link-hover-decoration: underline;

// Inputs & Buttons

$enable-rounded: true;
$enable-shadows: true;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-transition: color .3s ease-in-out, background-color .3s ease-in-out;
$border-radius: px-to-rem(10);
$border-radius-sm: px-to-rem(5);
$border-radius-lg: px-to-rem(20); // use border-radius-pill for full raunded corners
$input-btn-focus-width: px-to-rem(1);
$input-btn-focus-color: $clr-cyan;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;
$input-font-size: font-size('body-sm', 'mobile');
$input-font-weight: $font-weight-light;
$input-color: $clr-dark;
$input-border-color: $clr-bordergrey;
$input-focus-border-color: $clr-dark;
$input-padding-x: px-to-rem(16);
$input-padding-y: px-to-rem(9);
$form-select-font-size: $input-font-size;
$form-select-font-weight: $input-font-weight;
$form-select-color: $input-color;
$form-select-border-color: $input-border-color;
$form-select-focus-border-color: $input-focus-border-color;
$form-label-font-size: font-size('body-lg', 'mobile');
$form-label-font-weight: $input-font-weight;
$form-label-color: $clr-black;
$form-check-input-width: px-to-rem(40);
$form-check-min-height: px-to-rem(40);
$form-check-input-border-radius: $border-radius;
$form-check-input-border: var(--#{$prefix}border-width) solid $input-border-color;
$form-check-input-checked-bg-color: $clr-orange;
$form-check-input-checked-bg-image: none;
$form-check-input-focus-border: $clr-orange;
$form-check-radio-checked-bg-image: none;
$form-invalid-color: $clr-pomegranate;
$form-invalid-border-color: $clr-pomegranate;
$form-valid-border-color: $input-border-color;

// Dropdown

$dropdown-border-width: 0;
$dropdown-box-shadow: $box-shadow;
$dropdown-link-color: $clr-black;
$dropdown-link-hover-color: $clr-orange;
$dropdown-link-hover-bg: transparent;
$dropdown-link-active-color: $clr-orange;
$dropdown-link-active-bg: transparent;

// Modals

$modal-header-padding-y: px-to-rem(20);

// Breadcrumb

$breadcrumb-margin-bottom: 0;
$breadcrumb-item-padding-x: px-to-rem(10);
$breadcrumb-divider-color: $clr-darkgrey;
$breadcrumb-font-size: font-size('body-sm','mobile');