$btn-padding-y: px-to-rem(5);
$btn-padding-x: px-to-rem(20);
$btn-font-weight: $font-weight-semibold;

.btn {
    --bs-btn-padding-x: #{$btn-padding-x};
    --bs-btn-padding-y: #{$btn-padding-y};
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-border-width: 0;
    --bs-btn-font-size: #{font-size('cta', 'desktop')};
    --bs-btn-font-weight: #{$btn-font-weight};

    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    min-height: px-to-rem(52);

    &:not(.btn-outline*) {
        --bs-btn-color: var(--bs-white);
    }

    @include media-breakpoint-down(lg) {
        --bs-btn-font-size: #{font-size('cta', 'mobile')};

        min-height: px-to-rem(36);
    }

    &[class*='btn-outline'],
    &[data-bs-toggle] {
        --bs-btn-border-width: #{$btn-border-width};
    }

    &-primary {
        --bs-btn-color: var(--bs-white);
        --bs-btn-hover-bg: var(--bs-pomegranate);
        --bs-btn-active-bg: var(--bs-pomegranate);
        --bs-btn-active-color: var(--bs-white);

        &:disabled,
        &.disabled,
        fieldset:disabled & {
            color: var(--bs-white);
        }
    }

    &-secondary {
        --bs-btn-hover-bg: var(--bs-black);
    }

    &-success,
    &-warning,
    &-error,
    &-info {
        --bs-btn-color: var(--bs-white);
    }

    &-link {
        --bs-btn-hover-color: var(--bs-primary);
        --bs-btn-active-color: var(--bs-primary);
    }
}

a.btn {
    display: inline-flex;
    align-items: center;
}

a[data-bs-toggle],
.btn[data-bs-toggle] {
    &:not(.dropdown-toggle) {
        --bs-btn-active-color: var(--bs-btn-color);
        --bs-btn-hover-color: var(--bs-primary);
    }
}

button[type="submit"],
.btn-submit {
    --bs-btn-font-size: #{font-size('cta', 'desktop')};

    width: 100%;
    padding: px-to-rem(15) 0 px-to-rem(17);
    line-height: normal;
    min-height: px-to-rem(52);

    @include media-breakpoint-up(lg) {
        width: auto;
        min-width: px-to-rem(270);
    }
}

.btn-cancel,
.btn-back {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: font-size('body-xs', 'mobile');
    line-height: px-to-rem(15);
    text-decoration: none;
    gap: px-to-rem(5);
    color: $clr-dark;
    min-height: auto;

    &:hover {
        text-decoration: none;
        color: $clr-dark;
    }

    @include media-breakpoint-up(lg) {
        font-size: font-size('body-sm', 'desktop');
        line-height: px-to-rem(20);
    }

    .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: px-to-rem(16);
        height: px-to-rem(16);

        @include media-breakpoint-up(lg) {
            width: px-to-rem(21);
            height: px-to-rem(21);
        }
    }    
}