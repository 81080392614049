.section-account {
    padding-bottom: px-to-rem(64);

    &__header {
        margin-bottom: sp(5);

        .section__title {
            margin-bottom: px-to-rem(10);
        }
    }

    &__dashboard {
        .card-payment {
            margin-bottom: 0;

            p {
                margin: 0;

                &:first-of-type {
                    color: $clr-darkergrey;
                }

                &:last-of-type {
                    margin-bottom: px-to-rem(20);
                }
            }
        }

        .card-address,
        .card-payment {
            .card-dashboard__info:last-of-type {
                margin-bottom: px-to-rem(20);
            }
        }

        .card-order-history {
            .card-dashboard__info:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .card.card-order-invoices {
        padding: 0;
        margin: 0;
        border: none;

        .card-body {
            .card-row {
                border: none;

                &:not(:first-child) {
                    display: none;
                }
            }
        }
    }

    .card-expand-btn {
        display: inline-block;
        background: none;
        border: none;
        padding: 0;
        text-decoration: underline;
    }

    .card {
        border-color: $clr-orange;
        margin-top: px-to-rem(20);
        margin-bottom: px-to-rem(20);
        padding: px-to-rem(20);

        &:first-of-type {
            margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: px-to-rem(30);
        }

        .card-header {
            padding: 0 0 px-to-rem(13);
            margin-bottom: px-to-rem(20);
            border-radius: 0;
            background: none;
            border-bottom-color: $clr-orange;

            @include make-flex(row, center, space-between);

            @include media-breakpoint-up(lg) {
                padding-bottom: px-to-rem(16);
            }

            button {
                background: none;
                border: none;
                padding: 0;
                color: $clr-orange;
                font-size: px-to-rem(20);
                margin-left: px-to-rem(20);
            }
        }

        .card-title {
            margin: 0;
            color: $clr-orange;
            font-size: font-size('h3', 'mobile');
            font-weight: $font-weight-semibold;
            line-height: px-to-rem(26);

            @include media-breakpoint-up(lg) {
                font-size: font-size('h3', 'desktop');
                line-height: px-to-rem(32);
            }

            +div {
                @include make-flex(row, flex-end, center);

                .card-edit,
                button {
                    @include make-flex(row, center, center);

                    margin-right: 0;
                    padding: 0;
                    width: px-to-rem(24);
                    height: px-to-rem(24);
                }
            }
        }

        .card-edit {
            display: inline-block;
            padding: 0 px-to-rem(4);
            margin-right: px-to-rem(3);
            color: $clr-orange;
            text-decoration: none;
            font-size: px-to-rem(20);

        }

        .card-edit,
        .remove-address {
            .icon {
                font-size: px-to-rem(20);
            }
        }

        .card-body {
            padding: 0;
            color: $clr-darkgrey;
            line-height: px-to-rem(26);
            font-weight: $font-weight-normal;

            dl {
                margin-bottom: px-to-rem(10);

                &:last-of-type {
                    margin: 0;
                }
            }

            dt {
                font-weight: $font-weight-normal;
                color: $clr-darkergrey;
            }

            dd {
                margin: 0;
            }

            &.expanded {
                .card-body {
                    .card-row {
                        display: flex;
                        padding: 0;
                    }
                }
            }
        }

        .card-footer {
            @include make-flex(row, center, null);

            background: none;
            border: none;
            padding: 0;


            .card-link {
                font-size: font-size('cta', 'mobile');
                line-height: px-to-rem(17);
                font-weight: $font-weight-normal;
            }
        }

        &__title {
            font-size: font-size('body-xl', 'desktop');
            font-weight: $font-weight-normal;
            color: $clr-darkergrey;
            margin-bottom: px-to-rem(10);
        }

        &__info {
            font-weight: $font-weight-normal;
            margin-bottom: px-to-rem(10);
        }

        &__label {
            color: $clr-darkergrey;
        }

        .order-list {
            &__item:not(:first-of-type) {
                border-top: solid px-to-rem(1) $clr-grey;
                margin-top: px-to-rem(20);
                padding-top: px-to-rem(20);
            }
        }

        &#{$const-active} {
            background-color: rgba($clr-orange, 0.1);
        }

        &-order-invoices {
            .card-row {
                &__value {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .form-group {
        position: relative;
    }

    &__cta {
        display: inline-block;
        font-size: font-size('cta', 'mobile');
        line-height: px-to-rem(17);
        font-weight: $font-weight-normal;
    }

    &__address-book {
        margin: sp(6) 0;

        &.empty {
            margin-bottom: sp(5);
        }
    }

    &-newsletter {
        @include media-breakpoint-up(lg) {
            padding-top: sp(5);
        }

        .section__sidebar {
            @include media-breakpoint-up(lg) {
                @include make-flex(column, flex-end, null);
            }
        }

        .section__main-inner {
            @include media-breakpoint-up(lg) {
                padding: px-to-rem(32) 0;
            }
        }

        .section__main {
            p {
                font-weight: $font-weight-normal;
                color: $clr-btndark;
                margin: 0;
            }
        }

        .section__sidebar-inner {
            @include media-breakpoint-up(lg) {
                max-width: px-to-rem(411);
            }

            @include media-breakpoint-down(lg) {
                padding-top: sp(6);
                padding-bottom: sp(5);
            }
        }

        form.newsletter-form {
            @include media-breakpoint-up(lg) {
                .form-control {
                    max-width: px-to-rem(624);
                }
            }
        }
    }

    +.faqs-container {
        padding-top: sp(5);
        background-color: $clr-menugrey;

        @include media-breakpoint-up(lg) {
            // padding: sp(6) 0;
        }
    }
}