.modal {
    &-title {
        font-weight: $font-weight-normal;
    }

    .close {
        background: none;
        border: none;
        width: px-to-rem(24);
        height: px-to-rem(24);
        padding: 0;
        font-size: px-to-rem(20);
    }
}
