$card-spacer-y: px-to-rem(20);
$card-spacer-x: px-to-rem(20);
$card-border-radius: $border-radius-lg;
$card-border-color: $clr-grey;


.card {
    $parent: &;

    --bs-card-spacer-x: #{$card-spacer-x};
    --bs-card-spacer-y: #{$card-spacer-y};
    --bs-card-border-radius: #{$card-border-radius};
    --bs-card-border-color: #{$card-border-color};

    &--primary {
        border: px-to-rem(2) solid clr(primary);
        box-shadow: $box-shadow-lg;

        #{$parent}-body {
            padding: px-to-rem(32) px-to-rem(30);
        }
    }

    &-row {
        @include make-flex(row, center, space-between);

        border-bottom: px-to-rem(1) solid $card-border-color;
        padding: sp(4) 0;
        font-weight: $font-weight-normal;

        &:last-child {
            border: none;
            padding-bottom: 0;
        }

        &__label {
            color: $clr-dark;
        }

        &__value {
            font-weight: $font-weight-bold;
            color: $clr-dark;
        }
    }
}

.card-contact-telephone {
    font-size: font-size('body-xl', 'mobile');
    font-weight: $font-weight-normal;
    color: $clr-darkergrey;
    line-height: px-to-rem(26);

    @include media-breakpoint-down(lg) {
        .card-body {
            padding-left: px-to-rem(32);
            padding-right: px-to-rem(15);
        }
    }

    .contact-logo {
        margin-bottom: px-to-rem(11);
    }

    .contact-working-hours {
        font-size: px-to-rem(24);
        font-weight: $font-weight-bold;
        line-height: px-to-rem(30);
    }

    .contact-tel {
        margin: sp(4) 0 sp(2);
        font-size: font-size('h1', 'mobile');
        font-weight: $font-weight-bold;
        line-height: normal;

        a {
            text-decoration: none;
            color: $clr-orange;
        }
    }

    .contact-ps {
        font-size: font-size('body-sm', 'mobile');
        line-height: normal;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}