.section-faq {
    @include media-breakpoint-up(lg) {
        padding-top: sp(5);
    }

    .section__inner {
        @include media-breakpoint-down(lg) {
            overflow: hidden;
        }
    }

    .section__sidebar {
        @include media-breakpoint-up(lg) {
            @include make-flex(column, flex-end, null);
        }
    }

    .section__main-inner {
        @include media-breakpoint-up(lg) {
            padding-top: px-to-rem(32);
        }
    }

    .section__sidebar-inner {
        @include media-breakpoint-up(lg) {
            max-width: px-to-rem(411);
        }

        @include media-breakpoint-down(lg) {
            padding-top: px-to-rem(33);
            padding-bottom: px-to-rem(35);
        }
    }

    &__nav {
        @include make-flex(row, stretch, space-between);

        margin-bottom: sp(6);

        .btn-check + label.btn {
            width: 100%;
            border-color: $clr-orange;
            color: $clr-orange;
            font-size: font-size('body-xs','mobile');
            padding: px-to-rem(8) px-to-rem(20);

            &:hover,
            &:focus {
                border-color: $clr-orange;
            }
        }

        .btn-check:checked + label.btn {
            background-color: $clr-orange;
            color: $clr-white;
            box-shadow: inset 0 0 0 px-to-rem(1) $clr-orange;
        }

        @include media-breakpoint-down(lg) {
            width: calc(100vw - px-to-rem(16));
            white-space: nowrap;
            overflow-x: auto;
            padding: 0 sp(4) sp(3) 0;
            margin-bottom: px-to-rem(48);

            @include custom-scrollbars;
        }
    }

    .accordion {
        margin-bottom: sp(5);
    }
}

.faqs {
    max-width: px-to-rem(840);
    margin: 0 auto;
    padding: px-to-rem(22) 0 sp(6);
    gap: sp(5);

    @include make-flex(column, center, flex-start);

    &__title {
        text-align: center;
        margin-bottom: 0;
        color: $clr-darkergrey;
    }

    &__list {
        align-self: stretch;
    }

    &__link {
        @include make-flex(row, center, center);

        gap: px-to-rem(5);
        font-size: font-size('body-xs', 'mobile');
        line-height: px-to-rem(15);
        font-weight: $font-weight-semibold;
        color: $clr-dark;

        @include media-breakpoint-up(lg) {
            font-size: font-size('body-lg', 'desktop');
            line-height: px-to-rem(20);
        }

        @include media-breakpoint-down(lg) {
            align-self: flex-start;
        }
    }
}