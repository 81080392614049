/**
* Font imports
* 100 = hairline, 200 = thin, 300 = light, 400 = regular, 500 = medium, 600 = semibold, 700 = bold, 800 = heavy, 900 = black
*/

$font-import: () !default;
$font-import: map.merge((
    Montserrat: ( /* stylelint-disable-line */ 
        light: (weight: 300, style: normal),
        regular: (weight: 400, style: normal),
        medium: (weight: 500, style: normal),
        semibold: (weight: 600, style: normal),
        bold: (weight: 700, style: normal)
    )
), $font-import);

/**
* Return a font face import
* @param {string} $font The font name
* @param {string} $filename The file name
* @param {number} $font-weight The font weight
* @param {string} $font-style The font style
*/

@mixin font-import($font, $filename, $font-weight: 400, $font-style: normal) {
    @font-face {
        font-family: $font;
        font-style: $font-style;
        font-weight: $font-weight;
        font-display: swap;
        src: url('#{$filename}.woff2') format("woff2");
    }
}

/**
* Import all defined fonts
*/

@mixin font-imports() {
    @each $font, $settings in $font-import {
        @each $name, $format in $settings {
            @include font-import($font, '../../fonts/#{to-lower-case($font)}-#{to-lower-case($name)}', map_get($format, 'weight'), map_get($format, 'style'));
        }
    }
}

/**
* Import defined fonts
*/

@include font-imports;

/**
* CSS Font family names with websafe fallback
* usage:
* font-family: font-family(primary)
* font: 12px/24px font-family(primary)
*/

$font-family: () !default;
$font-family: map.merge((
    primary: (Montserrat, Arial, Helvetica, sans-serif) /* stylelint-disable-line */
), $font-family);

/**
* Return a font-family.
* @param {string} $name The font family name
*/

@function font-family($name: primary) {
    @if map_has_key($font-family, $name) {
        @return map_get($font-family, $name);
    } @else {
        @return map_get($font-family, primary);
    }
}
